import React,{useEffect} from "react";

function TermsUse() {
  useEffect(() => {
    window.scrollTo(0, 0);
    const handleScroll = () => {
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
}, []);
  return (
    <>
      <div className="bg-[#1F1F1F] pt-[7rem] pb-[4rem]">
        <div className="w-[90%] space-y-[42px] max-w-[1440px] mx-auto ">
          <div>
            <h3 className="text-[46px] text-[#fff] font-[700] ">
              Terms of Use
            </h3>
          </div>
          <div className="space-y-[20px]">
            <div className="space-y-[20px]">
              <h3 className="text-[28px] text-[#fff] font-[700]">
                1. Introduction
              </h3>
              <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">
                By using, visiting, or accessing any part of the Dreamcars
                website, subdomain, mobile application, or any platform we own
                or operate (collectively, the "Platform"), and/or registering an
                account, you agree to be bound by these Terms and Conditions,
                our Privacy Policy, our Cookies Policy, and any additional terms
                applicable to our services offered on the Platform
                (collectively, the "Terms"). This signifies your acceptance and
                understanding of all the Terms.
              </p>
              <div className="space-y-[10px]">
                <div className="space-y-[10px]">
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                    1.1 <span className="font-[400]">Agreement:</span>
                  </h3>
                  <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">
                    Please read these Terms carefully. If you disagree with them
                    or cannot accept them, you must refrain from using,
                    visiting, or accessing the Platform.
                  </p>
                </div>
                <div className="space-y-[10px]">
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  1.2 <span className="font-[400]">Updates to the Terms: :</span>
                  </h3>
                  <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">
                  We may modify these Terms at any time and for any reason, including to comply with applicable laws or regulatory requirements. The latest version of the Terms will always be available on the Platform. Your continued use of the Platform after such changes signifies your acceptance of the updated Terms.
                  </p>
                </div>
                <div className="space-y-[10px]">
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  1.3 <span className="font-[400]">Ownership and Licensing:</span>
                  </h3>
                  <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">
                  Dreamcars is operated by [Your Company Name], a company incorporated under the laws of [Your Country] with Company Number [Your Company Number]. (Replace with your actual company information)
                  </p>
                </div>
                <div className="space-y-[10px]">
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  1.4 <span className="font-[400]">Definitions: </span>
                  </h3>
                  <ul className="pl-8">
                    <li className="list-disc text-[#fff]">"You," "your," "customer," "user," or "participant" refers to any individual using the Platform or any services offered on it, including registered users.</li>
                    <li className="list-disc text-[#fff]">""Services" refers to all features, functionalities, and offerings available on the Dreamcars Platform, including facilitating fractional ownership of luxury cars, managing financial transactions related to fractional ownership, and any future offerings.</li>
                    <li className="list-disc text-[#fff]">""Platform" encompasses the Dreamcars website, subdomains, mobile applications, and any platform we own or operate.</li>
                  </ul>
                </div>
                <div className="space-y-[10px]">
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  1.5 <span className="font-[400]">Fractional Ownership and Platform Updates:  </span>
                  </h3>
                  <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">
                  Dreamcars reserves the right to update the Platform, modify the fractional ownership structure, or introduce new services at its sole discretion
                  </p>
                </div>
                
              </div>
            </div>
            <div className="space-y-[20px]">
              <h3 className="text-[28px] text-[#fff] font-[700]">
              2. Your Account     
              </h3>
              <div className="space-y-[10px]">
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  2.1 <span className="font-[400]">Eligibility and Age Requirement</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  2.1.1  <span className="font-[400]">To register for an account on the Dreamcars Platform ("Account"), you must meet the following criteria: </span>
                  </h3>
                  <ul className="pl-8">
                    <li className="list-disc text-[#fff] text-[18px]">You must be at least 18 years old or of the legal age required in your jurisdiction to participate in our services.</li>
                    <li className="list-disc text-[#fff] text-[18px]">It is your sole responsibility to ensure that using the Dreamcars Platform and its services complies with the laws applicable to you in your country of residence.</li>
                  </ul>
                  <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">Dreamcars prohibits users under the age of 18 from accessing or using the Platform and its services.
              </p>
                </div>
            </div>
            <div className="space-y-[20px]">
              <h3 className="text-[28px] text-[#fff] font-[700]">
              2. Your Account     
              </h3>
              <div className="space-y-[10px]">
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  2.2.1 <span className="font-[400]">Account Creation:</span>
                  </h3>
                  <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">To participate in Dreamcars services, such as making fractional ownership investments, you must register for an Account.</p>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  2.2.2 <span className="font-[400]">Registration Information: </span>
                  </h3>
                  <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">During registration, you'll be required to provide complete and accurate information, including a mobile number, email address, username, password, and any other details designated as mandatory on the registration form.</p>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  2.2.3 <span className="font-[400]">Legal Name:</span>
                  </h3>
                  <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">By registering, you agree to provide your legal name. We may conduct measures to verify the accuracy of the information you provide. You cannot modify your legal name on your account; however, you can contact Dreamcars customer support in case of an honest mistake or other exceptional circumstances.</p>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  2.2.4 <span className="font-[400]">Username and Password:</span>
                  </h3>
                  <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">If you choose a username, password, or any other security credential during registration, you are responsible for maintaining the confidentiality of this information. Avoid disclosing your login details to anyone, as we are not liable for any misuse of your account resulting from compromised credentials. We will never request your password or any password-related information.</p>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  2.2.5 <span className="font-[400]">Restricted Users:</span>
                  </h3>
                  <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">Employees, former employees of service providers, and affiliated individuals are prohibited from registering accounts or using Dreamcars services and promotions. This restriction also applies to family members of the aforementioned individuals. Violations will result in permanent account closure and any winnings derived from such activity will be forfeited. Only the deposited amount will be returned.</p>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  2.2.6 <span className="font-[400]">Account Transfer Prohibition:</span>
                  </h3>
                  <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">Your Dreamcars account cannot be transferred, sold, or pledged to another person. This prohibition encompasses the transfer of any account value or assets, including but not limited to ownership of accounts, fractional ownership stakes, deposits, and any rights or claims associated with these assets. This also includes but is not limited to encumbrance, pledging, assigning, usufruct, trading, brokering, hypothecation, or gifting your account to any third party, company, individual, foundation, or association.</p>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  2.3 <span className="font-[400]">Account Issues</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  2.3.1<span className="font-[400]">If you forget your password or think that somebody knows details of your personal data, moreover if you suspect that another user is taking an unfair advantage through cheating or collusion you must report the suspicion to us.</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  2.3.2<span className="font-[400]">We reserve the right to declare any transaction void partially or in full if we, at our own discretion, would deem it obvious that any of the following circumstances have occurred:.</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  2.3.4 <span className="font-[400]">The Account holder and or people associated with the Account holder are directly or indirectly avoiding the rules of the Website;</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  2.3.5 <span className="font-[400]">The ownership or the transaction has been directly or indirectly affected by criminal activity;</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  2.3.6 <span className="font-[400]">The value of the $DCARS coin has significantly been changed due to a public announcement in relation to the market;</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  2.3.7 <span className="font-[400]">Transactions have been made that would not have been accepted otherwise, but they were accepted during periods when the Website has been affected by technical problems;</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  2.3.8 <span className="font-[400]">Due to an error, such as a mistake, misprint, technical error, human error, force majeure or otherwise, transactions have been offered, made, and or accepted due to this error.</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  2.3.9<span className="font-[400]"> When we close or suspend an Account for whatever reason, we reserve the right to close any future Accounts that may be registered by the same person, device, address, or that may use the same digital wallets or payment methods and void/cancel all transactions of those Accounts.</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  2.3.11 <span className="font-[400]">By registering the Account on the Website you undertake, declare and warrant that:</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  2.3.12 <span className="font-[400]">You are over 18 years of age or such higher minimum legal age of majority as stipulated in the laws of jurisdiction applicable to you and, under the laws applicable to you, you are allowed to participate in the transactions offered on the Website.</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  2.3.13 <span className="font-[400]">You will use this Website and your Account solely and exclusively for the purpose of your genuine participation in the transactions and not for any financial or other operations; your participation will be strictly in your personal non-professional capacity for recreational and entertainment reasons only.</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  2.3.14 <span className="font-[400]">You participate on your own behalf and not on behalf of any other person;.</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  2.3.17 <span className="font-[400]">You are solely responsible for reporting and accounting for any taxes applicable to you under relevant laws for any earnings that you receive from Dreamcars.</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  2.3.18 <span className="font-[400]"> All money that you deposit into your Account is not tainted with any illegality and, in particular, does not originate from any illegal activity or source.</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  2.3.19 <span className="font-[400]">You understand that by participating in transactions you take the risk of losing money deposited into your Account.</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  2.3.20  <span className="font-[400]">You shall not be involved in any fraudulent, collusive, fixing, or other unlawful activity in relation to your or third parties’ participation in any of the transactions and shall not use any software-assisted methods or techniques or hardware devices for your participation. Dreamcars hereby reserves the right to invalidate or close your Account or invalidate your participation in a transaction in the event of such behavior.</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  2.3.21 <span className="font-[400]">In relation to deposits and withdrawals of funds into and from your Account, you shall only use credit cards and other financial instruments that are valid and lawfully belong to you.</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  2.3.23 <span className="font-[400]">Transactions made on the Website should be conducted in the same manner as transactions conducted in any other setting. You shall be courteous to other participants and representatives of Dreamcars and shall avoid rude or obscene comments, including in chat rooms.</span>
                  </h3>
                </div>
            </div>
           
            <div className="space-y-[20px]">
              <h3 className="text-[28px] text-[#fff] font-[700]">
              3. Multi-Account Policy
              </h3>
              <div className="space-y-[10px]">
              <h3 className="text-[20px] text-[#fff] font-[700]">
              3.1 <span className="font-[400]">One Account per User: Dreamcars allows you to register and use only one account on the platform.</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  3.2 <span className="font-[400]">Single Account per Household/Device: Only one account is permitted per household, IP address, and computer or device. If multiple users within a household intend to use Dreamcars, the account holder must inform Dreamcars in advance..</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  3.3 <span className="font-[400]">Consequences of Multiple Accounts: Creating or attempting to create multiple accounts may result in Dreamcars taking action against any or all of your accounts. This action may include blocking accounts, voiding fractional ownership of luxury cars linked to duplicate accounts, withholding $DCARS coins, gifts, and withdrawal requests. Additionally, any rental income earned through duplicate accounts during their existence will be forfeited.</span>
                  </h3>
              </div>
            </div>
            <div className="space-y-[20px]">
              <h3 className="text-[28px] text-[#fff] font-[700]">4. Payouts</h3>
              <div className="space-y-[10px]">
              <h3 className="text-[20px] text-[#fff] font-[700]">
              4.1  <span className="font-[400]">Availability of Funds: Once your fractional luxury car purchase is finalized on the Dreamcars platform, the corresponding ownership rights will be reflected in your account..</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  4.2  <span className="font-[400]">Erroneous Ownership: If Dreamcars mistakenly assigns fractional ownership of a car to your account due to technical or human error, the ownership will be rectified. Dreamcars reserves the right to adjust your account accordingly.</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  4.3 <span className="font-[400]">Verification Procedures: Dreamcars may implement additional verification measures for any rental income payouts. This includes the right to conduct such procedures at a</span>
                  </h3>
              </div>
            </div>
            <div className="space-y-[20px]">
              <h3 className="text-[28px] text-[#fff] font-[700]">5. Deposits</h3>
              <div className="space-y-[10px]">
              <h3 className="text-[20px] text-[#fff] font-[700]">
              5.1 <span className="font-[400]">Funding Your Account: To participate in fractional car ownership on Dreamcars, you'll need to deposit funds into your account. Deposits can be made online using various methods, potentially including credit cards and cryptocurrencies (subject to availability in your region). Cash or checks are not accepted.</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  5.2 <span className="font-[400]">Supported Currencies: Dreamcars may accept deposits in various currencies. Any received funds in a currency different from your account currency will be converted at the prevailing exchange rate, with any associated fees borne by you.</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  5.3 <span className="font-[400]">Verification Procedures: Dreamcars reserves the right to implement additional Know Your Customer (KYC) verification measures for deposits. Failure to provide necessary documents may result in account closure.</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  5.4<span className="font-[400]"> Authorized Deposits: By depositing funds, you confirm they are authorized and won't be disputed or reversed through a third party, avoiding any potential liabilities.</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  5.5  <span className="font-[400]">Anti-Money Laundering: To prevent money laundering, Dreamcars may require deposited funds to be used at least once before allowing withdrawals.</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  5.6 <span className="font-[400]">Third-Party Deposits: Dreamcars prohibits third-party deposits (by friends, relatives, etc.). All payments must originate from an account/system or credit card registered under the account holder's name. Detected third-party deposits will result in forfeited winnings returned to Dreamcars, with the original deposit refunded to the rightful owner of the payment method. Any associated fees for returning funds will be borne by the recipient.</span>
                  </h3>
              </div>
              <div className="space-y-[20px]">
                <h3 className="text-[20px] text-[#fff] font-[700]">
                6. Withdrawals
                </h3>
                <div className="space-y-[10px]">
             <div>
             <h3 className="text-[20px] text-[#fff] font-[700]">
              6.1 <span className="font-[400]">Verification Requirements: For your first withdrawal, Dreamcars may require Know Your Customer (KYC) documents, including:</span>
                  </h3>
                  <ul className="pl-8">
                    <li className="list-disc text-[#fff]">Credit card pictures (both sides) with the first 6 and last 4 digits visible. Remaining digits and CVV/CVC code should be hidden.</li>
                    <li className="list-disc text-[#fff]">Bank statement copy</li>
                    <li className="list-disc text-[#fff]">Personal identification document</li>
                    <li className="list-disc text-[#fff]">Proof of address</li>
                    <li className="list-disc text-[#fff]">Proof of ownership for any other used payment methods</li>
                  </ul>
                  <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">
                  Additional requirements based on the chosen withdrawal method may apply.
                </p>
             </div>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  6.2 <span className="font-[400]">Withdrawal Limits: Dreamcars reserves the right to adjust the maximum withdrawal amount per transaction for each payment method without prior notice.</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  6.3 <span className="font-[400]">Rental Income and Account Balance: Withdrawals are limited to your available account balance, which reflects any accumulated rental income from your fractional car ownership.</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  6.4 <span className="font-[400]">Processing Times: Withdrawal requests are typically processed within two business days. However, delays may occur depending on the chosen method, additional account checks, and public holidays.</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  6.5 <span className="font-[400]">Withdrawal Methods: Withdrawals are generally processed through the same method used for depositing funds. Available withdrawal methods can be found within the Dreamcars platform's "Cashier" section.</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  6.6 <span className="font-[400]">Cancelled Withdrawals: Cancelled withdrawals will return the funds to your account for further use. Dreamcars is not liable for any activity-related losses occurring after a withdrawal cancellation (by you or Dreamcars).</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  6.7 <span className="font-[400]">No Refunds: Dreamcars transactions involving fractional car ownership and $DCARS coins are considered final. Refunds or cancellations are not available.</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  6.8 <span className="font-[400]">Withdrawal Limits: Daily and monthly withdrawal limits may apply. Unless a higher amount is pre-approved, the maximum withdrawal is typically USD 1,000 per day and USD 30,000 per month (or equivalent in your account currency).</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  6.9 <span className="font-[400]">Verification Procedures: Dreamcars may request additional legal identification documents (e.g., passport copies, ID cards) to verify your identity before processing a withdrawal. Phone verification, face verification, or other checks might also be implemented.</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  6.10 <span className="font-[400]">Multiple Withdrawals: Only one pending withdrawal request per payment method is allowed at a time. Additionally, some methods may limit you to one withdrawal request per 24 hours.</span>
                  </h3>
              </div>
              </div>
              <div className="space-y-[20px]">
                <h3 className="text-[20px] text-[#fff] font-[700]">
                7. Account Closure
                </h3>
                <div className="space-y-[10px]">
              <h3 className="text-[20px] text-[#fff] font-[700]">
              7.1 <span className="font-[400]">Account Closure Option: You can close your Dreamcars account by contacting customer support and requesting a withdrawal of your remaining account balance, minus any applicable withdrawal fees. Before closure, ensure you have no outstanding fractional car ownership transactions.</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  7.2  <span className="font-[400]">Closure Methods: Dreamcars reserves the right to close your account and refund your available withdrawal balance (minus any fees) at their sole discretion, without needing to provide a reason or prior notice.</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  7.3 <span className="font-[400]">Rental Income and Account Balance: Account closure will result in the withdrawal of your remaining account balance, which reflects any accumulated rental income from your fractional car ownership.</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  7.4 <span className="font-[400]">Fraudulent Activity: Dreamcars reserves the right to refuse withdrawal requests suspected of fraud. In such cases, the account will be suspended, and the withdrawal will not be processed.</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  7.5 <span className="font-[400]">Account Review and Classification: Dreamcars may review and classify user accounts at its discretion. If an account is found to be violating Dreamcars' terms of service, the account may be suspended or closed.</span>
                  </h3>
              </div>
              </div>
              <div className="space-y-[20px]">
                <h3 className="text-[20px] text-[#fff] font-[700]">
                8. Personal Information
                </h3>
                <div className="space-y-[10px]">
              <h3 className="text-[20px] text-[#fff] font-[700]">
              8.1 <span className="font-[400]">8.1 Data Protection: Dreamcars respects and complies with applicable data protection laws regarding your personal information. You can learn more about how your information is processed by reviewing Dreamcars' Privacy Policy, available on the platform.</span>
                  </h3>
                  
              </div>
              </div>
              <div className="space-y-[20px]">
                <h3 className="text-[20px] text-[#fff] font-[700]">
                9. Account Security and Breaches
                </h3>
                <div className="space-y-[10px]">
              <h3 className="text-[20px] text-[#fff] font-[700]">
              9.1 <span className="font-[400]">Criminal Activity: Dreamcars reserves the right to close accounts and report any suspected criminal activity (including money laundering or fraud) to relevant authorities. This may involve blocking account balances, voiding withdrawals, and forfeiting deposits and any rental income earned.</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  9.2 <span className="font-[400]">Unauthorized Activity: Dreamcars can disable accounts and forfeit balances (including deposits and rental income) if users are found to be gaining or attempting to gain an unfair advantage. This includes sharing card information, collaborating with other users, or using chip dumping/transfer techniques. Dreamcars employs automated and manual methods to detect such activity and investigate user complaints. Proactive and random gameplay and account reviews are also conducted.</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  9.3 <span className="font-[400]">Misconduct and Fraud: Dreamcars may block user accounts and forfeit balances without prior notice upon detecting fraudulent, unlawful, dishonest, or improper activity. This includes using proxy services to mask location, placing bets through third parties, or engaging in any deceptive behavior. Dreamcars reserves the right to report such activity to relevant authorities (including financial institutions) and pursue legal action.</span>
                  </h3>
              </div>
              </div>
            </div>
            <div className="space-y-[20px]">
              <h3 className="text-[28px] text-[#fff] font-[700]">
              10.  Live Chat
              </h3>
              <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">
              Dreamcars may offer a live chat feature for user support. This platform is moderated and subject to controls. Dreamcars reserves the right to review chat conversations and keep records of them. To ensure a productive and positive experience using the Dreamcars live chat, please follow these guidelines:
              </p>
              <div className="space-y-[10px]">
              <h3 className="text-[20px] text-[#fff] font-[700]">
              10.1 <span className="font-[400]">Respectful Communication: When interacting with Dreamcars support staff, it's important to maintain a courteous and professional tone. This means avoiding profanity, offensive language, or discriminatory remarks. Remember, a respectful approach will lead to a more efficient and helpful interaction for everyone involved.</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  10.2 <span className="font-[400]">Dreamcars Focus: The live chat is designed for inquiries and discussions directly related to Dreamcars. This includes questions about fractional car ownership, rental income concerns, or any other issues you might encounter while using the platform. Abstain from promoting or advertising other online entities within the chat, as it disrupts the flow of communication and isn't relevant to Dreamcars' services.</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  10.3 <span className="font-[400]">Accuracy and Positivity: Strive for truthful and constructive communication when discussing Dreamcars, its services, or any issues you encounter. If you have a problem, explain it clearly and concisely. Maintaining a positive and collaborative approach while seeking assistance will help Dreamcars support resolve your concerns more effectively.</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  10.4 <span className="font-[400]">Fair Play: The live chat is intended to be a space for legitimate inquiries and support requests. Do not use it to collude with other users or engage in activities that violate Dreamcars' terms of service. If you observe any suspicious activity within the live chat, report it to Dreamcars support promptly. By working together, we can maintain a fair and secure environment for all Dreamcars users.</span>
                  </h3>
              </div>
            </div>
            <div className="space-y-[20px]">
              <h3 className="text-[28px] text-[#fff] font-[700]">
              11. Technical Issues and Cancellations
              </h3>
              <div className="space-y-[10px]">
              <h3 className="text-[20px] text-[#fff] font-[700]">
              11.1 <span className="font-[400]">Technical Disruptions and Refunds: Dreamcars strives to provide a reliable platform. However, they are not liable for downtime, server disruptions, lagging, or technical issues that may affect accessing account information, initiating fractional car ownership transactions, or managing rental income. Refunds for such occurrences are granted at Dreamcars' sole discretion.</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  11.2 <span className="font-[400]">Disclaimer of Liability: Dreamcars does not assume responsibility for any damages or losses arising from using the platform, its content, or technical malfunctions. This includes, but is not limited to, delays, interruptions, data loss, communication failures, misuse by users, or errors in content.</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  11.3 <span className="font-[400]">System Malfunction and Transactions: In the event of a system malfunction within Dreamcars, all ongoing fractional car ownership transactions or attempts to manage rental income will be suspended. Dreamcars will work to rectify the issue and ensure the accuracy of transactions upon resolution.</span>
                  </h3>
                <div>
                <h3 className="text-[20px] text-[#fff] font-[700]">
                  11.4 <span className="font-[400]">Transaction Failure and Correction: If you initiate a fractional car ownership transaction or attempt to collect rental income but encounter a system failure, Dreamcars will take corrective actions. This may involve:</span>
                  </h3>
                  <ul className="pl-8">
                    <li className="list-disc text-[#fff]">Crediting the attempted transaction amount back to your account.</li>
                    <li className="list-disc text-[#fff]">If your account is no longer active, Dreamcars will issue a refund using an approved method.</li>
                    <li className="list-disc text-[#fff]">If you had any accrued rental income before the failure, Dreamcars will credit the equivalent value back to your account (if it still exists) or issue a refund through an approved method.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="space-y-[20px]">
              <h3 className="text-[28px] text-[#fff] font-[700]">12. Dreamcars Terms and Agreements</h3>
              <div className="space-y-[10px]">
              <h3 className="text-[20px] text-[#fff] font-[700]">
              12.1 <span className="font-[400]">Assignment by Dreamcars: Dreamcars reserves the right to assign or lawfully transfer its rights and obligations under these Terms and Agreements to another entity.</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  12.2 <span className="font-[400]">User Restrictions: You are not permitted to assign or transfer your rights and obligations under these Terms and Agreements to another person or entity.</span>
                  </h3>
              </div>
            </div>
            <div className="space-y-[20px]">
              <h3 className="text-[28px] text-[#fff] font-[700]">
              13. Complaints and Inquiries
              </h3>
              <div className="space-y-[10px]">
              <h3 className="text-[20px] text-[#fff] font-[700]">
              13.1 <span className="font-[400]">How to Submit a Complaint or Inquiry: If you have a concern or question about your Dreamcars account or experience, you can contact customer support by emailing [email address removed].</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  13.2 <span className="font-[400]">Complaint Resolution: Dreamcars strives to resolve all reported matters promptly and efficiently. Please provide clear and concise details in your email to facilitate a faster resolution.</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  13.3 <span className="font-[400]">Transaction Inquiries: For any questions or disputes regarding specific transactions within your Dreamcars account, you can contact customer support at the same email address. Be sure to include relevant details about the transaction in your inquiry. Dreamcars will review the matter, but please note that their decision on the transaction will be final..</span>
                  </h3>
              </div>
            </div>
            <div className="space-y-[20px]">
              <h3 className="text-[28px] text-[#fff] font-[700]">
              14. Terms and Agreements
              </h3>
              <div className="space-y-[10px]">
              <h3 className="text-[20px] text-[#fff] font-[700]">
              14.1 <span className="font-[400]">Entire Agreement: These Terms and Agreements, along with the Dreamcars Privacy Policy, Cookies Policy, and any other referenced documents, guidelines, or rules posted on the platform, constitute the complete agreement and understanding between you and Dreamcars concerning your use of the platform. They supersede any prior or contemporaneous communications, proposals, or agreements (electronic, oral, or written) between you and Dreamcars regarding Dreamcars, except in cases of fraud.</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  14.2 <span className="font-[400]">Admissibility of Electronic Records: A printed copy of these Terms and any electronically provided notices will be admissible in judicial or administrative proceedings concerning these Terms to the same extent and under the same conditions as other business documents originally generated and maintained in printed form.</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  14.3 <span className="font-[400]">Severability: If any provision of these Terms is found to be illegal or unenforceable, that provision will be severed from these Terms, and all other provisions will remain valid and enforceable.</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  14.4 <span className="font-[400]">Governing Law and Jurisdiction: These Terms are governed by the laws of Ajeltake Island Majuro, Marshall Islands and any disputes arising from them will be subject to the jurisdiction of the courts in Ajeltake Island Majuro, Marshall Islands]. The parties also agree to the rules of arbitration in accordance with applicable law..</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  14.5 <span className="font-[400]">Language Interpretation: In the case of any inconsistency between the content of these Terms in different languages, the English version of the Dreamcars platform shall prevail.</span>
                  </h3>
              </div>
            </div>
            <div className="space-y-[20px]">
              <h3 className="text-[28px] text-[#fff] font-[700]">
              15. Copyright and Trademark
              </h3>
              <div className="space-y-[10px]">
              <h3 className="text-[20px] text-[#fff] font-[700]">
              15.1 <span className="font-[400]">Ownership and Protection: Dreamcars owns all trademarks and logos associated with the platform. Any unauthorized use of these trademarks or logos may result in legal action.</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  15.2 <span className="font-[400]">Company Information: Dreamcars is operated by Dreamcars LTD, a company registered in the Marshall Islands with Registration Number Reg. No. 113028. Their registered address is Ajeltake Road, Ajeltake Island Majuro, Marshall Islands.</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  15.3 <span className="font-[400]">Website Monitoring Restriction: You agree not to use any automated or manual tools to monitor Dreamcars' website pages or their content.</span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  15.4 <span className="font-[400]">Unauthorized Use: Any unauthorized use or reproduction of Dreamcars' content may be subject to legal prosecution.</span>
                  </h3>
              </div>
            </div>
            <div className="space-y-[20px]">
              <h3 className="text-[28px] text-[#fff] font-[700]">
              16. Promotions: Terms and Conditions
              </h3>
              <div className="space-y-[10px]">
              <h3 className="text-[20px] text-[#fff] font-[700]">
              16. <span className="font-[400]">No Promotions Dreamcars currently does not offer any promotions, bonuses, or reward programs. This section will be updated if Dreamcars introduces such programs in the future.</span>
                  </h3>
                  
              </div>
            </div>
           
          </div>
        </div>
      </div>
    </>
  );
}

export default TermsUse;
