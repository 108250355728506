import React from "react";
import { ToastContainer, toast } from 'react-toastify';

import { Link } from "react-router-dom";

import Link1 from "../../assects/images/ftricon (1).png";
import Link2 from "../../assects/images/ftricon (2).png";
import Link3 from "../../assects/images/ftricon (3).png";
import Link4 from "../../assects/images/ftricon (4).png";
import Link5 from "../../assects/images/ftricon (5).png";

import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();
  const handleCopy = () => {
    navigator.clipboard.writeText("https://dreamcars.co/")
      .then(() => {
        toast.success('Copied!');
      })
      .catch((error) => {
        console.error('Error copying:', error);
        toast.error('Failed to copy.');
      });
  };
  return (
    <>
      <div className="bg-[#000000]">
        <div className="w-[90%] mx-auto py-12">
          <div className="space-y-[20px]">
            <div className="flex justify-between items-center">
              <p className="text-[20px] xs:text-[18px] font-[500] text-[#fff] max-w-[456px] w-[100%]">Dreamcars allows you to buy, sell, and trade shares of luxury cars on the blockchain while earning daily income from rentals.</p>
              <div className="space-x-[36px] 2xl:flex xl:flex lg:flex md:flex sm:hidden hidden ">
                <a className="text-[20px] font-[900] text-[#fff]" href="#hero">What is Dreamcars?</a>
                <a className="text-[20px] font-[900] text-[#fff]" href="#whitepaper">Whitepaper</a>
                <a className="text-[20px] font-[900] text-[#fff]" href="#roadmap">Roadmap</a>
                <a className="text-[20px] font-[900] text-[#fff]" href="#token">Tokenomics</a>
                <a className="text-[20px] font-[900] text-[#fff]" href="#faqs">FAQs</a>
              </div>
            </div>
            <div className="">
              <p className="text-[20px] xs:text-[18px] font-[300] text-[#fff]">Dreamcars LLC operates the website Dreamcars.co (“Platform”). By using the Platform, you accept our Terms of Service, Privacy Policy, Cookie Policy, and the DCARS Token sale agreement. Cryptocurrency may be unregulated in your jurisdiction. The value of cryptocurrencies may go down as well as up. Profits may be subject to capital gains or other taxes applicable in your jurisdiction. Nothing on this website should be construed as an offer or recommendation to buy, sell, or invest. You are solely responsible for determining whether any purchase or related transaction is appropriate for you based on your personal objectives, financial situation, and risk tolerance. You should consult with trusted, licensed legal professionals and advisors for legal, tax, insurance, or business advice. Dreamcars does not guarantee any performance, outcome, or return of capital for any cryptocurrency posted on this website. By accessing this site and any pages thereof, you agree to be bound by the DCARS sales agreement. Buying cryptocurrencies involves risk and may result in partial or total loss</p>
            </div>
            <div className="space-y-[30px] xs:space-y-[15px]">
              <div className="flex space-x-[30px]">
                <p className="text-[#fff] text-[16px] xs:text-[14px]">EN</p>
                <Link to="/privacypolicy">
                  <p className="text-[#fff] text-[16px] xs:text-[14px]">{t('Footer.Policy')}</p>
                </Link>
                <Link to="/termsuse">
                  <p className="text-[#fff] text-[16px] xs:text-[14px]">{t('Footer.Terms')}</p>
                </Link>
                <Link to="/tokensale">
                  <p className="text-[#fff] text-[16px] xs:text-[14px]">Token Sale Agreement</p>
                </Link>
                <Link to="/contactus">
                  <p className="text-[#fff] text-[16px] xs:text-[14px]">Contact Us</p>
                </Link>
              </div>
              <hr />
              <div className="flex justify-between items-center">
                <p className="text-[16px] xs:text-[10px] text-[#fff]">2024 Dreamcars.co All rights reserved</p>
                <div className="flex space-x-3 items-end">
                  <img className="h-[26px] xs:h-[18px] cursor-pointer w-[26px] xs:w-[18px]" src={Link1} alt="" />
                  <a href="https://dreamcarss-organization.gitbook.io/dreamcars-white-paper/">
                    <img className="h-[30px]  xs:h-[20px] cursor-pointer w-[30px] xs:w-[20px]" src={Link5} alt="" /></a>
                  <a href="https://twitter.com/dreamcars_bsc">
                    <img className="h-[30px]  xs:h-[20px] cursor-pointer w-[30px] xs:w-[20px]" src={Link4} alt="" /></a>
                  <a href="https://t.me/+pjg6PE6KXHI5MzRh">
                    <img className="h-[30px]  xs:h-[20px] cursor-pointer w-[30px] xs:w-[20px]" src={Link3} alt="" /></a>
                  <a href="https://www.instagram.com/dreamcars_bsc/"><img className="h-[30px]  xs:h-[20px] cursor-pointer w-[30px] xs:w-[20px]" src={Link2} alt="" /></a>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
}

export default Footer;
