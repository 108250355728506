import React, { useState, useEffect, useRef } from "react";
import Button from "../../components/Button/button";

import Logo from "../../assects/logo/Logo.svg";

import wallet from "../../assects/images/Wallet.png";
import walletblack from "../../assects/images/walletblack.png";
import WalletBlue from "../../assects/svgs/walletsvg.svg";
import globe from "../../assects/images/globe.png";
import flg from "../../assects/svgs/fg (13).svg";
import fg1 from "../../assects/svgs/fg (1).svg";
import fg2 from "../../assects/svgs/fg (2).svg";
import fg3 from "../../assects/svgs/fg (3).svg";
import fg4 from "../../assects/svgs/fg (4).svg";
import fg5 from "../../assects/svgs/fg (5).svg";
import fg6 from "../../assects/svgs/fg (6).svg";
import fg7 from "../../assects/svgs/fg (7).svg";
import fg8 from "../../assects/svgs/fg (8).svg";
import fg9 from "../../assects/svgs/fg (9).svg";
import fg10 from "../../assects/svgs/fg (10).svg";
import fg11 from "../../assects/svgs/fg (11).svg";
import fg12 from "../../assects/svgs/fg (12).svg";
import fg13 from "../../assects/svgs/fg (13).svg";
import fg14 from "../../assects/svgs/fg (14).svg";

import i18n from "i18next";
import { useMoralis } from "react-moralis";
import { useTranslation } from "react-i18next";
import HoverDropDn from "../../components/HoverDropDn";


import { ConnectButton } from '@rainbow-me/rainbowkit';
import {
  useConnectModal,
  useChainModal,
} from '@rainbow-me/rainbowkit';
import MyWallet from './MyWallet';


const Navbar = ({ handelClick }) => {
  const [selectedLang, setSelectedLang] = useState({
    img: fg13,
    title: "English",
  });
  const [langStatus, setLangStatus] = useState(false);

  const [lang, setLang] = useState("Eng");

  const { openConnectModal } = useConnectModal();
  const { openChainModal } = useChainModal();
  const [open, setOpen] = useState(false);
  const [currentChain, setCurrentChain] = useState('Ethereum');
  const [navBarOpen, setNavBarOpen] = useState(false);
  const { t } = useTranslation();

  const locales = {
    en: { img: fg13, title: "English" },
    gr: { img: fg12, title: "German" },
    fr: { img: fg11, title: "French" },
    es: { img: fg10, title: "Spanish" },
    it: { img: fg9, title: "Italian" },
    du: { img: fg8, title: "Dutch" },
    tu: { img: fg7, title: "Turkey" },
    jp: { img: fg6, title: "Japan" },
    kr: { img: fg5, title: "Korean" },
    nr: { img: fg4, title: "Norwegian" },
    ru: { img: fg3, title: "Russian" },
    ch: { img: fg2, title: "Chinese" },
    pr: { img: fg1, title: "Portugese" },
    vt: { img: fg14, title: "Vietnamese" },
  };

  const handleLangDropdown = () => {
    setLangStatus(!langStatus);
  };
  const handleNavbarMenu = () => {
    setNavBarOpen(!navBarOpen);
  };
  const handleNavbarMenuClose = () => {
    setNavBarOpen(false);
  };

  const dropdownRef = useRef(null);
  const handleLanguageChange = (locale) => {
    i18n.changeLanguage(locale);

    // Set the selected language and close the dropdown
    setSelectedLang(locales[locale]);
    setLangStatus(false);
  };

  
    const [url, setURL] = useState("en");
    useEffect(() => {

        if (window.location.href !== "") {
            if (window.location.href.slice(-2) === 'en') {
                setURL("en")
                setLang("English")
                i18n.changeLanguage("en");
                setSelectedLang(locales["en"]);
                i18n.changeLanguage(window.location.href.slice(-2))
            }
            if (window.location.href.slice(-2) === 'gr') {
                setLang("German")
                setURL("gr")
                i18n.changeLanguage("gr");
                setSelectedLang(locales["gr"]);
                i18n.changeLanguage(window.location.href.slice(-2))
            }
            if (window.location.href.slice(-2) === 'fr') {
                setLang("French")
                setURL("fr")
                i18n.changeLanguage("fr");
                setSelectedLang(locales["fr"]);
                i18n.changeLanguage(window.location.href.slice(-2))
            }

            if (window.location.href.slice(-2) === 'es') {
                setLang("Spanish")
                setURL("es")
                i18n.changeLanguage("es");
                setSelectedLang(locales["es"]);
                i18n.changeLanguage(window.location.href.slice(-2))
            }

            if (window.location.href.slice(-2) === 'it') {
                setLang("Italian")
                setURL("it")
                i18n.changeLanguage("it");
                setSelectedLang(locales["it"]);
                i18n.changeLanguage(window.location.href.slice(-2))
            }

            if (window.location.href.slice(-2) === 'jp') {
                setLang("Japan")
                setURL("jp")
                i18n.changeLanguage("jp");
                setSelectedLang(locales["jp"]);
                i18n.changeLanguage(window.location.href.slice(-2))
            }
            if (window.location.href.slice(-2) === 'du') {
                setLang("Dutch")
                i18n.changeLanguage("du");
                setSelectedLang(locales["du"]);
                setURL("du")
                i18n.changeLanguage(window.location.href.slice(-2))
            }
            if (window.location.href.slice(-2) === 'tu') {
                setLang("Turkish")
                i18n.changeLanguage("tu");
                setSelectedLang(locales["tu"]);
                setURL("tu")
                i18n.changeLanguage(window.location.href.slice(-2))
            }
            if (window.location.href.slice(-2) === 'kr') {
                setLang("Korean")
                setURL("kr")
                i18n.changeLanguage("kr");
                setSelectedLang(locales["kr"]);
                i18n.changeLanguage(window.location.href.slice(-2))
            }
            if (window.location.href.slice(-2) === 'nr') {
                setLang("Norwegian")
                i18n.changeLanguage("nr");
                setSelectedLang(locales["nr"]);
                setURL("nr")
                i18n.changeLanguage(window.location.href.slice(-2))
            }
            if (window.location.href.slice(-2) === 'ru') {
                setLang("Russian")
                i18n.changeLanguage("ru");
                setSelectedLang(locales["ru"]);
                setURL("ru")
                i18n.changeLanguage(window.location.href.slice(-2))
            }
            if (window.location.href.slice(-2) === 'ch') {
                setLang("Chinese")
                i18n.changeLanguage("ch");
                setSelectedLang(locales["ch"]);
                setURL("ch")
                i18n.changeLanguage(window.location.href.slice(-2))
            }
            if (window.location.href.slice(-2) === 'pr') {
                setLang("Portuguese")
                i18n.changeLanguage("pr");
                setSelectedLang(locales["pr"]);
                setURL("pr")
                i18n.changeLanguage(window.location.href.slice(-2))
            }
            if (window.location.href.slice(-2) === 'vt') {
                setLang("Vietnamese")
                i18n.changeLanguage("vt");
                setSelectedLang(locales["vt"]);
                setURL("vt")
                i18n.changeLanguage(window.location.href.slice(-2))
            }
        }
    }, [])
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setLangStatus(false);
      // Add any other logic to close dropdowns here
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative bg-[#0D0904]">
      <nav className="  w-full px-[20px] py-1 xs:py-1">
        <header className="flex justify-between items-center">
          <div className="flex items-center space-x-10 h-16 max-w-screen-2xl sm:px-0 xs:px-0 lg:px- ">
            <div className="flex items-center">
              <div className="flex items-center w-[100%] max-w-[200px] pb-[0.7rem] xs:pb-[0rem] xs:w-[136px]">
                <a href="https://dreamcars.co/">
                  <img
                    src={Logo}
                    className="h-[55px] xs:h-[27px] sm:h-[53px] w-[100%]"
                    alt="DreamCars"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="flex items-center space-x-[22px] xs:space-x-[8px]">
            <div className="flex items-center justify-between xs:justify-start space-x-5 xs:space-x-0 ">
              <div className="flex justify-between  items-center space-x-6 xs:hidden sm:hidden md:hidden ">
                <h4 className="text-white  text-[18px] lg:text-[10px] xl:text-[14px] font-[400] hover:text-[#a0a0a0]">
                  {" "}
                  <a href="#dreamcar">{t("main.navlink1")}</a>{" "}
                </h4>
                <h4 className="text-white  text-[18px] lg:text-[10px] xl:text-[14px] font-[400] hover:text-[#a0a0a0]">
                  {" "}
                  <a href="#whitepaper">{t("main.navlink2")}</a>
                </h4>
                <h4 className="text-white  text-[18px] lg:text-[10px] xl:text-[14px] font-[400] hover:text-[#a0a0a0]">
                  {" "}
                  <a href="#roadmap">{t("main.navlink4")}</a>{" "}
                </h4>
                <h4 className="text-white  text-[18px] lg:text-[10px] xl:text-[14px] font-[400] hover:text-[#a0a0a0]">
                  {" "}
                  <a href="#token">{t("main.navlink5")}</a>{" "}
                </h4>
                <h4 className="text-white  text-[18px] lg:text-[10px] xl:text-[14px] font-[400] hover:text-[#a0a0a0]">
                  {" "}
                  <a href="#faqs">{t("main.navlink6")}</a>{" "}
                </h4>
              </div>
            </div>
            <div className="flex items-center space-x-[9px] xs:space-x-[5px] ">
              <li className="flex items-center xs:hidden sm:hidden md:hidden">
                <div className="relative border border-[#fff] rounded-[30px] px-2">
                  <button
                    onClick={handleLangDropdown}
                    id="dropdownDefaultButton"
                    data-dropdown-toggle="dropdown"
                    class="text-white font-medium rounded-lg text-sm w-[full]  py-2.5 text-center inline-flex items-center px-2"
                    style={{}}
                    type="button"
                  >
                    <img src={selectedLang.img} width={20} className="mr-2" />
                    {selectedLang.title}

                    <svg
                      className={`fill-current text-white h-4 w-4 transform ${langStatus ? "rotate-180" : ""
                        }`}
                      aria-hidden="true"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 9l-7 7-7-7"
                      ></path>
                    </svg>
                  </button>

                  {langStatus && (
                    <>
                      <div className="relative">
                        <div
                          id="dropdown"
                          ref={dropdownRef}
                          className="absolute font-[Poppins] w-[750px]   -left-[630%] top-[21px] z-10 "
                        >
                          <HoverDropDn
                            onLanguageChange={handleLanguageChange}
                            locales={locales}
                          />
                        </div>
                      </div>
                      <div onClick={handleLangDropdown} className="relative">
                        <div className="bg-[#f0f8ff00] !w-[1400px] -top-[2] right-[-513%]  absolute"></div>
                      </div>
                      {/* <div onClick={handleLangDropdown} className='relative'>
                                            <div className='bg-[#faebd700] !w-[1400px] -top-[2] right-[-513%] h-[100vh] absolute '></div>
                                        </div> */}
                    </>
                  )}
                </div>
              </li>

              <div className='flex items-center '>

                <ConnectButton.Custom>
                  {({
                    account,
                    chain,
                    authenticationStatus,
                    mounted,
                  }) => {
                    // Note: If your app doesn't use authentication, you
                    // can remove all 'authenticationStatus' checks
                    const ready = mounted && authenticationStatus !== 'loading';
                    const connected =
                      ready &&
                      account &&
                      chain &&
                      (!authenticationStatus ||
                        authenticationStatus === 'authenticated');
                    if (chain?.name)
                      setCurrentChain(chain?.name)
                    return (
                      <div
                        {...(!ready && {
                          'aria-hidden': true,
                          'style': {
                            opacity: 0,
                            pointerEvents: 'none',
                            userSelect: 'none',
                          },
                        })}
                      >
                        {(() => {
                          if (!connected) {
                            return (

                              <button className="border border-[#fff] relative rounded-[30px] text-[#fff] 2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[16px] text-[12px] pr-6 font-[700] rounded-full 2xl:w-[220px] xl:w-[220px] lg:w-[220px] md:w-[220px] sm:w-[160px] w-[140px] xs:h-[31px] h-[40px]" onClick={openConnectModal}>
                                {" "}
                                {t("main.navBtn")}{" "}
                                <span className="absolute top-[0%] flex justify-center items-center right-[0%] w-[39px] xs:w-[30px] xs:h-[30px] inline-block h-[39px] bg-[#fff] rounded-[30px]">
                                  <img src={walletblack} alt="" />
                                </span>{" "}
                              </button>

                            );
                          }

                          if (chain.unsupported) {
                            return (
                              <button onClick={openChainModal} type="button">
                                Wrong network
                              </button>
                            );
                          }

                          return (
                            <div>
                              <button className='px-3  rounded-full items-center justify-start w-[165px] xs:w-[150px] lg:w-[150px] h-[38px] flex items-center  p-1.5 text-[13px] xs:text-[12px] font-semibold font-[poppins] text-white space-x-1 relative bg-[#3f3f3f]' onClick={() => setOpen(!open)}>
                                <div className='absolute bg-white -right-[2.5px] h-[40px] w-[40px] rounded-full border border-[#3f3f3f] flex justify-center items-center'>
                                  <span className='  '>
                                    <img className='' src={WalletBlue} alt="" />
                                  </span>
                                </div>
                                <span>
                                  {/* {userWallet} */}
                                  {account.address.slice(0, 6)}...{account.address.slice(-4)}
                                </span>

                              </button>
                              {
                                open ? <div className="z-10 absolute right-20 sm:-right-0 xs:-right-0 top-[70px] xs:top-[80px]  sm:w-full xs:w-full w-[23%] lg:w-[23%] rounded-[1rem] bg-[#000000] block h-[440px]"  >
                                  <MyWallet accounts={account.address} />
                                </div> :
                                  <></>
                              }
                            </div>
                          );
                        })()}
                      </div>
                    );
                  }}
                </ConnectButton.Custom>
              </div>
              {/* <button
                type="button"
                onClick={handleNavbarMenu}
                className="p-1 sm:mr-4 xl:hidden lg:hidden 2xl:hidden"
              >
                <svg
                  className="w-6 h-6 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 6h16M4 12h16M4 18h16"
                    fill="white"
                  />
                </svg>
              </button> */}
            </div>
          </div>

        </header>
        {navBarOpen && (
          <div className="">
            <div className=" relative  space-y-4 py-6 px-6 2xl:hidden xl:hidden lg:hidden !h-[1800px] bg-[#111010] w-full left-0 absolute">
              <button
                className="text-white  text-[16px] font-[400] hover:text-[#a0a0a0]"
                onClick={handleNavbarMenuClose}
              >
                <a href="#dreamcar">{t("main.navlink1")}</a>
              </button>
              <h4
                className="text-white  text-[16px] font-[400] hover:text-[#a0a0a0]"
                onClick={handleNavbarMenuClose}
              >
                <a href="#whitepapertag">{t("main.navlink2")}</a>
              </h4>
              <h4
                className="text-white  text-[16px] font-[400] hover:text-[#a0a0a0]"
                onClick={handleNavbarMenuClose}
              >
                {" "}
                <a href="#roadmap">{t("main.navlink4")}</a>
              </h4>{" "}
              <h4
                className="text-white  text-[16px] font-[400] hover:text-[#a0a0a0]"
                onClick={handleNavbarMenuClose}
              >
                {" "}
                <a href="#token">{t("main.navlink5")}</a>
              </h4>
              <h4
                className="text-white  text-[16px] font-[400] hover:text-[#a0a0a0]"
                onClick={handleNavbarMenuClose}
              >
                <a href="#faqs">{t("main.navlink6")}</a>
              </h4>
              <li className="flex items-center ">
                <div className="relative ">
                  <button
                    onClick={handleLangDropdown}
                    id="dropdownDefaultButton"
                    data-dropdown-toggle="dropdown"
                    class="text-white font-medium rounded-lg text-sm w-[full]  py-2.5 text-center inline-flex items-center "
                    style={{}}
                    type="button"
                  >
                    <img src={selectedLang.img} width={20} className="mr-2" />
                    {selectedLang.title}
                    <svg
                      className={`fill-current text-white h-4 w-4 transform ${langStatus ? "rotate-180" : ""
                        }`}
                      aria-hidden="true"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 9l-7 7-7-7"
                      ></path>
                    </svg>
                  </button>

                  {langStatus && (
                    <>
                      <div className="relative">
                        <div
                          id="dropdown"
                          ref={dropdownRef}
                          className="absolute xs:w-[300px] xs:mx-auto w-[750px] xs:left-[10%] -left-[860%] top-[3px] z-10 "
                        >
                          <HoverDropDn
                            onLanguageChange={handleLanguageChange}
                            locales={locales}
                          />
                        </div>
                      </div>
                      <div onClick={handleLangDropdown} className="relative">
                        <div className="bg-[#f0f8ff00] w-[1400px]  -top-[2] right-[-513%]  absolute"></div>
                      </div>
                      {/* <div onClick={handleLangDropdown} className='relative'>
                                            <div className='bg-[#faebd700] !w-[1400px] -top-[2] right-[-513%] h-[100vh] absolute '></div>
                                        </div> */}
                    </>
                  )}
                </div>
              </li>
            </div>
          </div>
        )}
      </nav>
    </div>
  );
};
export default Navbar;
