import React from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import "swiper/css/navigation";
import { Navigation, Mousewheel,Pagination, Keyboard } from "swiper/modules";

import benefits1 from "../../assects/images/benficon (3).png";
import benefits2 from "../../assects/images/benficon (4).png";
import benefits3 from "../../assects/images/benficon (5).png";
import benefits4 from "../../assects/images/benficon (2).png";
import benefits5 from "../../assects/images/benficon (1).png";
import benefits6 from "../../assects/images/benficon (6).png";
import Button from "../../components/Button/button";

const BenefitData = [
  {
    id: 1,
    image: benefits1,
    titlekey: "BenefitSection.cardTitle",
    titlekey2: "BenefitSection.cardTitle2",
    subtitlekey: "BenefitSection.cardSubtitle",
  },
  {
    id: 2,
    image: benefits2,
    titlekey: "BenefitSection.cardTitle21",
    subtitlekey: "BenefitSection.cardSubtitle2",
  },
  {
    id: 3,
    image: benefits3,
    titlekey: "BenefitSection.cardTitle3",
    subtitlekey: "BenefitSection.cardSubtitle3",
  },
  {
    id: 4,
    image: benefits4,
    titlekey: "BenefitSection.cardTitle4",
    subtitlekey: "BenefitSection.cardSubtitle4",
  },
  {
    id: 5,
    image: benefits5,
    titlekey: "BenefitSection.cardTitle5",
    subtitlekey: "BenefitSection.cardSubtitle5",
  },
  {
    id: 6,
    image: benefits6,
    titlekey: "BenefitSection.cardTitle6",
    subtitlekey: "BenefitSection.cardSubtitle6",
  },
];

const BenefitSection = () => {
  const { t } = useTranslation();

  return (
    <div className="bgbenfsecgr">
      <div className="w-[90%] max-w-[1440px] space-y-[2rem] py-[4rem] mx-auto">
        <div className="text-center">
          <h2 className="font-[700] text-[#fff] leading-[40px] text-[40px] xs:leading-[30px] xs:text-[24px] ">
            {t("BenefitSection.title")}
          </h2>
        </div>
        <div className="2xl:block xl:block lg:block md:block sm:hodden hidden">
          <div
            className="2xl:grid 2xl:grid-cols-3 xl:grid xl:grid-cols-3 lg:grid lg:grid-cols-3 md:grid md:grid-cols-2
             sm:grid sm:grid-cols-2 grid grid-cols-1 gap-y-4 gap-x-5 "
          >
            {BenefitData.map((item, id) => (
              <div
                key={id}
                className="text-white z-[2]  px-10 py-6 space-y-3 cardbenf border border-[#fff]"
              >
                <div className="justify-center items-center 2xl:h-[49px] xl:h-[49px] lg:h-[49px] md:h-[49px] sm:h-[49px] h-[49px]">
                  <img
                    src={item.image}
                    alt=""
                    className="2xl:h-[49px] xl:h-[49px] lg:h-[49px] md:h-[49px] sm:h-[49px] h-[49px]"
                  />
                </div>
                <div className="space-y-[15px] ">
                  <h3 className=" font-[700] text-[28px]  ">
                    {t(item.titlekey)}
                  </h3>
                  <p
                    id="howtobuy"
                    className=" font-[400] text-[14px] xs:text-[14px] text-[#FFF]"
                  >
                    {t(item.subtitlekey)}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="2xl:hidden xl:hidden lg:hidden md:hidden sm:block block">
          <Swiper
            breakpoints={{
              360: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              600: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              750: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              1124: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              1440: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
            }}
            pagination={true}
            mousewheel={true}
            keyboard={true}
            spaceBetween={14}
            className="mySwiper"
            navigation={true}
            modules={[Navigation, Pagination, Mousewheel, Keyboard]}
          >
             {BenefitData.map((item, id) => (
              <SwiperSlide >
              <div
                key={id}
                className="text-white z-[2] h-[305px] px-8 py-6 space-y-3 cardbenf border border-[#fff]"
              >
                <div className="justify-center items-center 2xl:h-[49px] xl:h-[49px] lg:h-[49px] md:h-[49px] sm:h-[49px] h-[49px]">
                  <img
                    src={item.image}
                    alt=""
                    className="2xl:h-[49px] xl:h-[49px] lg:h-[49px] md:h-[49px] sm:h-[49px] h-[49px]"
                  />
                </div>
                <div className="space-y-[15px] xs:space-y-[6px]">
                  <h3 className="font-[700] text-[28px] xs:text-[20px] leading-[30px] xs:leading-[25px] ">
                    {t(item.titlekey)}
                  </h3>
                  <p
                    id="howtobuy"
                    className=" font-[400] text-[14px] xs:text-[18px] text-[#FFF]"
                  >
                    {t(item.subtitlekey)}
                  </p>
                </div>
              </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="flex justify-center items-center">
          <Button
            link="#hero"
            text={`${t("BetaSection.btn")}`}
            classes="font-[700] bgcolor w-[169px] py-[12px] xs:text-[14px] rounded-[10px]"
          />
        </div>
      </div>
    </div>
  );
};
export default BenefitSection;
