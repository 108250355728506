import React from "react";

function MediaCards({ cardContainer, imgClass, src, para }) {
  return (
    <>
      <div
        className={`2xl:w-[100%] mdgebg xl:w-[100%] lg:w-[100%] md:w-[100%] sm:w-[90%] w-[90%] mx-auto h-[335px]   2xl:pt-[44px] xl:pt-[44px] lg:pt-[44px] md:pt-[44px] sm:pt-[34px] pt-[30px] 2xl:pb-[35px] xl:pb-[35px] lg:pb-[35px] md:pb-[63px] sm:pb-[49px] pb-[30px] px-[42px] xs:px-[30px] space-y-[10px] ${cardContainer}`}
      >
       <div className="2xl:h-[100px] flex justify-center xl:h-[100px] lg:h-[100px] md:h-[100px] sm:h-[100px] h-[100px] 2xl:mb-6 xl:mb-4 lg:mb-0 md:mb-4 sm:mb-0 mb-0">
       <img className={`max-h-[100px] p-[10px] ${imgClass}`} src={src} alt="" />
       </div>
       <div className="">
       <p className="2xl:text-[14px] xl:text-[14px] lg:text-[14px] md:text-[14px] sm:text-[14px] text-[14px] text-[white] text-center font-[400] 2xl:leading-[20.30px] xl:leading-[20.30px] lg:leading-[20.30px] md:leading-[20.30px] sm:leading-[20.30px] leading-[20.01px] xs:pt-3">"
          {para}"
        </p>
       </div>
      </div>
    </>
  );
}

export default MediaCards;
