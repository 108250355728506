import React from "react";
import { useTranslation } from "react-i18next";

import Buyimg from "../../assects/images/buysec.png";

const BuySection = () => {
  const { t } = useTranslation();

  return (
    <div className="backgroundgrupside" id="htb">
      <div className="w-[90%] max-w-[1440px] space-y-[2rem] xs:space-y-[1rem] py-[4rem] xs:py-[2rem] xs:py-[2rem] mx-auto">
        {/* <p style={{ top: "-100px", position: "relative" }} id="howItWork"></p> */}
        <h2 className="text-[40px] xs:text-[24px]   text-center font-[700] text-[#fff]  leading-[40px]">
          {t("BuySection.title")}
        </h2>
        <div className="pt-[31px] xs:pt-[10px] w-full block xs:space-y-3 sm:space-y-3 md:space-y-3 items-end justify-between 2xl:flex xl:flex lg:flex md:flex sm:flex flex 2xl:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col flex-col">
          <div className="2xl:w-[40%] xl:w-[40%] lg:w-[40%] md:w-[100%] sm:w-full w-full pb-7 xs:pb-1">
            <div className="w-[80%] xs:w-[100%] space-y-4 mx-auto">
              <div className="buybggrside xs:py-6  py-5 2xl:space-y-1 xs:space-y-1 px-8 ">
                <h3 className="2xl:text-[20px] text-[#fff] text-[16px] xs:text-[18px]  font-[700] leading-[18px]  ">
                  {t("BuySection.cardTitle1")}
                </h3>
                <h4 className="2xl:block xl:block lg:block md:hidden sm:hidden hidden  2xl:text-[21px] text-[16px]  xs:text-[18px] font-[900]  text-white  leading-[29px] ">
                  {t("BuySection.cardSubtitle1")}
                </h4>
                <h4 className="2xl:hidden xl:hidden lg:hidden md:block sm:block block  2xl:text-[21px] text-[16px]  xs:text-[20px] font-[800]   text-white leading-[29px] ">
                  {t("BuySection.cardSubtitle1")}
                </h4>
                <p className="max-w-[297px] w-[100%] text-[18px] xs:text-[18px] xs:leading-[22px]  text-[white] leading-[130%]  font-[400]  ">
                  {t("BuySection.cardPara1")}
                </p>
              </div>
              <div className="buybggrside xs:py-6  py-5 2xl:space-y-1 px-8 xs:space-y-1 ">
                <h3 className="2xl:text-[20px] text-[#fff] text-[16px] xs:text-[18px]  font-[700] leading-[18px]  ">
                  {t("BuySection.cardTitle2")}
                </h3>

                <h4 className="2xl:block xl:block lg:block md:hidden sm:hidden hidden  2xl:text-[21px] text-[16px]  xs:text-[18px] font-[900]  text-white  leading-[29px] ">
                  {t("BuySection.cardSubtitle2")}
                </h4>
                <h4 className="2xl:hidden xl:hidden lg:hidden md:block sm:block block  2xl:text-[21px] text-[16px]  xs:text-[20px] font-[800]  text-white leading-[29px] ">
                  {t("BuySection.cardSubtitle2")}
                </h4>
                <p className="max-w-[297px] w-[100%] text-[18px] xs:text-[18px] xs:leading-[22px]  text-[white] leading-[130%]  font-[400]  ">
                  {t("BuySection.cardPara2")}
                </p>
              </div>
              <div className="buybggrside xs:py-6  py-5 2xl:space-y-1 px-8 xs:space-y-1 ">
                <h3 className="2xl:text-[20px] text-[#fff] text-[16px] xs:text-[18px]  font-[700] leading-[18px]  ">
                  {t("BuySection.cardTitle3")}
                </h3>

                <h4 className="2xl:block xl:block lg:block md:hidden sm:hidden hidden  2xl:text-[21px] text-[16px]  xs:text-[18px] font-[900]  text-white leading-[29px] ">
                  {t("BuySection.cardSubtitle3")}
                </h4>
                <h4 className="2xl:hidden xl:hidden lg:hidden md:block sm:block block  2xl:text-[21px] text-[16px]  xs:text-[20px] font-[800]  text-white leading-[29px] ">
                  {t("BuySection.cardSubtitle3")}
                </h4>
                <p className="max-w-[297px] w-[100%] text-[18px] xs:text-[18px] xs:leading-[22px] text-[white] leading-[130%]  font-[400]  ">
                  {t("BuySection.cardPara3")}
                </p>
              </div>
              <div className="2xl:flex xl:flex lg:flex md:hidden sm:hidden xs:hidden pt-3 flex mt-14 xs:justify-center sm:justify-center space-x-3">
                <a className="w-[100%]" href="#hero">
                  <button className="bgcolor xs:text-[14px] flex justify-center items-center  text-[#1C1D23] rounded-full flex justify-center items-center text-[16px] 2xl:h-[54px]  xl:h-[54px]  lg:h-[45px] md:h-[45px] sm:h-[45px] h-[45px]   font-[700]  w-[100%] space-x-3">
                  {t("BuySection.btn1")}
                  </button>
                </a>
                <a className="w-[100%]" href="#hero">
                  <button className="bgcolor xs:text-[14px] flex justify-center items-center  text-[#1C1D23] rounded-full flex justify-center items-center text-[16px] 2xl:h-[54px]  xl:h-[54px]  lg:h-[45px] md:h-[45px] sm:h-[45px] h-[45px]   font-[700]  w-[100%] space-x-3">
                  {t("BuySection.btn2")}
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div className="2xl:hidden xl:hidden lg:hidden xs:w-[100%] xs:mx-auto md:block sm:flex xs:flex pt-3 xs:pt:2  flex mt-0 xs:pb-4 xs:justify-center sm:justify-center space-x-[20px]">
            <a href="#hero">
              <button className="bgcolor xs:text-[16px] xs:w-[150px]  text-[#1C1D23] rounded-full flex justify-center items-center text-[14px] 2xl:h-[54px]  xl:h-[54px]  lg:h-[45px] md:h-[45px] sm:h-[45px] h-[45px]   font-[700]  w-[110px] space-x-3">
              {t("BuySection.btn1")}
              </button>
            </a>
            <a href="" target="#">
            <button className="bgcolor xs:text-[16px] xs:w-[150px]  text-[#1C1D23] rounded-full flex justify-center items-center text-[14px] 2xl:h-[54px]  xl:h-[54px]  lg:h-[45px] md:h-[45px] sm:h-[45px] h-[45px]   font-[700]  w-[110px] space-x-3 ">
            {t("BuySection.btn2")}
              </button>
            </a>
          </div>
          <div className="2xl:w-[50%]  xl:w-[50%]  lg:w-[50%] md:w-[100%] sm:w-full w-full">
            <img className="" src={Buyimg} alt="" />
          </div>

        </div>
      </div>
    </div>
  );
};
export default BuySection;
