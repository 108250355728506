import React from "react";
import Home from "../Page/Home";
import Footer from "./Footer/Footer";
import { Routes, Route, Navigate } from "react-router-dom";
import Navbar from "./Navbar";
import Contactus from "../Page/Contactus";
import PrivacyPolicy from "../Page/PrivacyPolicy";
import TermsUse from "../Page/TermsUse";
import TokenSale from "../Page/tokenSale";

const Layout = ({ handelClick }) => {
  return (
    <>
      <div className="">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contactus" element={<Contactus />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/termsuse" element={<TermsUse />} />
          <Route path="/tokensale" element={<TokenSale />} />
        </Routes>
      </div>
    </>
  );
};
export default Layout;
