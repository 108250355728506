import React, {useEffect} from "react";

function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
    const handleScroll = () => {
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
}, []);
  return (
    <>
      <div className="bg-[#1F1F1F] pt-[7rem] pb-[4rem]">
        <div className="w-[90%] space-y-[42px] max-w-[1440px] mx-auto ">
          <div>
            <h3 className="text-[46px] text-[#fff] font-[700] ">
              Privacy Policy
            </h3>
          </div>
          <div className="space-y-[20px]">
            <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">
              Dreamcars LTD operates the website Dreamcars.co (“Platform”). By
              using the Platform, you accept our Terms of Service, Privacy
              Policy, Cookie Policy, and the DCARS Token sale agreement.
              Cryptocurrency may be unregulated in your jurisdiction. The value
              of cryptocurrencies may go down as well as up. Profits may be
              subject to capital gains or other taxes applicable in your
              jurisdiction. Nothing on this website should be construed as an
              offer or recommendation to buy, sell, or invest. You are solely
              responsible for determining whether any purchase or related
              transaction is appropriate for you based on your personal
              objectives, financial situation, and risk tolerance. You should
              consult with trusted, licensed legal professionals and advisors
              for legal, tax, insurance, or business advice. Dreamcars does not
              guarantee any performance, outcome, or return of capital for any
              cryptocurrency posted on this website. By accessing this site and
              any pages thereof, you agree to be bound by the DCARS sales
              agreement. Buying cryptocurrencies involves risk and may result in
              partial or total loss <br /> <br />
              We may periodically make changes to this Privacy Policy and will
              notify you of these changes by posting the modified terms on our
              platforms. We recommend that you revisit this Privacy Policy
              regularly.
            </p>
            <div className="space-y-[20px]">
              <h3 className="text-[28px] text-[#fff] font-[700]">INFORMATION COLLECTED
              </h3>
              <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">
                We consider information that identifies you as an individual,
                like your name, date of birth, address, email, phone number, and
                similar details, to be Personal Information ("Personal
                Information"). When you use the Dreamcars platform, register for
                an account, or participate in fractional car ownership services,
                you may provide us with this Personal Information. This could
                include contact details, billing information, transaction
                history related to your car ownership stake, website
                preferences, and any feedback you offer. <br /> <br />
                Dreamcars stores this information securely. Additionally, our
                servers maintain an activity log whenever you interact with our
                services. This log collects administrative and traffic data to
                ensure service quality, such as your IP address, access times,
                pages visited, language used, and browser type. We assure you
                that we collect no Personal Information without your knowledge
                and consent.
              </p>
            </div>
            <div className="space-y-[20px]">
              <h3 className="text-[28px] text-[#fff] font-[700]">
                MEANS OF COLLECTING AND PROCESSING DATA
              </h3>
              <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">
                We collect your Personal Information in a few ways. First, we
                may automatically collect certain data as described above.
                Second, you may directly provide Personal Information when you
                use our services, register for an account, or participate in
                fractional car ownership on the Dreamcars platform. Finally, we
                may receive your Personal Information from third-party sources
                like online vendors, service providers, or lawfully acquired
                customer lists. <br /> <br />
                Dreamcars works with trusted third-party service providers to
                support the platform, process transactions, and manage accounts.
                We will have access to any information you provide to these
                vendors, and it will only be used as outlined in this Privacy
                Policy. We take steps to ensure these third parties maintain
                your privacy as well.
              </p>
            </div>
            <div className="space-y-[20px]">
              <h3 className="text-[28px] text-[#fff] font-[700]">INFORMATION USE 
              </h3>
              <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">
                We use your Personal Information to run Dreamcars and deliver
                our fractional car ownership services. This includes providing
                customer support, performing security checks, processing
                transactions, facilitating your participation in promotions we
                may offer, and meeting operational needs. We may share your
                information with trusted partners for these purposes. We may
                also use your Personal Information to send you promotional
                offers and information about Dreamcars services, as well as
                offerings from our carefully selected partners. This allows us
                to expand the range of products and services available and
                improve your overall experience. While Dreamcars doesn't
                currently conduct surveys, we may use your information to
                personalize your experience on the platform or tailor future
                offerings based on your activity. Participation in any such
                programs would be entirely voluntary. By accepting a contest
                prize (if we offer them in the future), you consent to the use
                of your name for promotional purposes (unless prohibited by
                law). You can choose not to receive promotional information.
                However, if you don't opt out, we may use your contact details
                to send you information about Dreamcars, other fractional
                ownership opportunities we partner with, and offerings from our
                trusted partners.
              </p>
            </div>
            <div className="space-y-[20px]">
              <h3 className="text-[28px] text-[#fff] font-[700]">CERTAIN EXCLUDED DISCLOSURES
              </h3>
              <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">
                We disclose your Personal Information only in limited
                circumstances. When required by law, or if we believe it's
                necessary to comply with legal processes, court orders, or
                similar legal obligations, we will disclose your information. We
                may also disclose your information to protect the rights,
                property, or safety of Dreamcars, our users, or the public,
                including situations where we need to prevent fraud or illegal
                activity. If we suspect fraudulent activity on your account,
                such as payment fraud or the use of stolen credit cards, we may
                share your information with relevant institutions like banks,
                credit card companies, and authorities. Additionally, Dreamcars
                reserves the right to share information related to fraudulent
                activity involving the use of $DCARS tokens. In some instances,
                your anonymized data may be used for research on preventing
                gambling addiction. These anonymized datasets can be shared with
                relevant institutions for research purposes.
              </p>
            </div>
            <div className="space-y-[20px]">
              <h3 className="text-[28px] text-[#fff] font-[700]">ACCESS
              </h3>
              <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">
               
Dreamcars empowers you to control your information. You can choose to opt-out of receiving promotional emails from us by utilizing the unsubscribe function within our emails, adjusting your account settings on the platform, or contacting customer service directly. We also respect your right to access and update your Personal Information stored on Dreamcars. This ensures you can maintain the accuracy and completeness of your data. Additionally, if you have any questions or concerns about your Personal Information, how it's used, or if you'd like to request deletion (subject to legal requirements), you can contact Dreamcars customer service for assistance.
              </p>
            </div>
            <div className="space-y-[20px]">
              <h3 className="text-[28px] text-[#fff] font-[700]">COOKIES</h3>
              <div className="space-y-[20px]">
                <h3 className="text-[20px] text-[#fff] font-[700]">
                  Information placed on your device
                </h3>
                <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">
                  When you access Dreamcars services, we may store information
                  on your device using cookies. These are small text files that
                  websites use to record your preferences and browsing activity.
                  We may also use Flash cookies, which are similar to browser
                  cookies but allow us to remember information across different
                  Dreamcars sites. Neither cookies nor Flash cookies can access
                  or use other data on your computer. We solely use these
                  methods to track your activity on our platform. Cookies help
                  us monitor traffic, improve our services, and personalize your
                  experience. Additionally, we use Flash cookies and third-party
                  cookies to deliver more relevant and desirable advertisements
                  to you.
                </p>
              </div>
              <div className="space-y-[20px]">
                <h3 className="text-[20px] text-[#fff] font-[700]">
                  Strictly necessary cookies
                </h3>
                <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">
                  Strictly necessary cookies play a vital role in ensuring
                  Dreamcars functions properly. These cookies allow you to
                  navigate the platform, access secure areas, and utilize
                  essential features like making fractional car ownership
                  transactions. Without them, the smooth operation of Dreamcars
                  would be compromise
                </p>
              </div>
              <div className="space-y-[20px]">
                <h3 className="text-[20px] text-[#fff] font-[700]">
                  During the registration process
                </h3>
                <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">
                  When you register for an account on Dreamcars, we use cookies
                  to store relevant information. These cookies help us recognize
                  you as a returning user and ensure you have seamless access to
                  the services you need, such as managing your fractional car
                  ownership stake. Additionally, the data collected through
                  these cookies allows us to gain insights into your browsing
                  behavior and preferences. This helps us personalize your
                  experience on the platform and make your visits more
                  enjoyable.
                </p>
              </div>
              <div className="space-y-[20px]">
                <h3 className="text-[20px] text-[#fff] font-[700]">
                  On our website
                </h3>
                <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">
                  When you visit Dreamcars, our servers may store cookies on
                  your device. These cookies fall into three main categories:
                </p>
                <ul className="pl-8 space-y-[20px]">
                  <li className="list-disc text-[#fff]">
                    Session Cookies: These temporary cookies are active only
                    during your browsing session and expire when you close your
                    browser. They help you navigate the platform efficiently
                    and, if you're a registered user, provide a more
                    personalized experience with relevant information.
                  </li>
                  <li className="list-disc text-[#fff]">
                    Persistent Cookies: These cookies remain on your device for
                    a predetermined period. Flash cookies also belong to this
                    category. They allow us to recognize you on future visits
                    and streamline your access to services like managing your
                    fractional car ownership.
                  </li>
                  <li className="list-disc text-[#fff]">
                    Analytical Cookies: These cookies help us understand how
                    visitors interact with Dreamcars. They provide insights into
                    user behavior and website traffic patterns, which helps us
                    improve the overall functionality and user experience of the
                    platform.
                  </li>
                </ul>
                <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">
                  You have control over cookies. Most web browsers accept
                  cookies by default, but you can usually modify your browser
                  settings to accept, decline, or receive notifications when a
                  new cookie is stored. The browser's Help menu will provide
                  specific instructions on how to manage cookies on your device.
                </p>
              </div>
              <div className="space-y-[20px]">
                <h3 className="text-[20px] text-[#fff] font-[700]">
                Flash cookies
                </h3>
                <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">
                The Dreamcars Flash Player settings empower you to manage Flash cookies on your terms. Access the Settings Manager to control how these cookies are stored. You can choose a global approach by navigating to the "Global Storage Settings" panel and unchecking the box labeled "Allow third-party Flash content to store information on your computer." This will prevent Flash cookies from being stored by any third-party websites you visit. Alternatively, you can opt for more granular control by utilizing the "Website Storage Settings" panel. This section allows you to manage Flash cookies on a website-by-website basis, letting you grant or deny permission for individual websites to store Flash cookies on your device. Remember that using an older Flash Player or web browser may limit your ability to access the Settings Manager. To ensure optimal functionality, we recommend updating your Flash Player and web browser to the latest versions. Please note that opting out of Flash cookies may limit your ability to experience all the interactive features on the Dreamcars platform.
                </p>
              </div>
            </div>
            <div className="space-y-[20px]">
              <h3 className="text-[28px] text-[#fff] font-[700]">
              CONSENT TO USE OF ELECTRONIC SERVICE PROVIDERS
              </h3>
              <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">
By agreeing to our Privacy Policy, you consent to sharing the personal information necessary to process these transactions. This may, in some cases, involve transferring your information beyond your national borders. Rest assured, we take steps to ensure our payment partners maintain your privacy as well.
              </p>
            </div>
            <div className="space-y-[20px]">
              <h3 className="text-[28px] text-[#fff] font-[700]">
              CONSENT TO SECURITY REVIEW
              </h3>
              <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">To ensure a safe and secure environment for everyone, Dreamcars reserves the right to conduct security checks at any time. These checks verify your registration information, service usage, and financial transactions against our Terms and Conditions and applicable laws. By using Dreamcars and agreeing to our terms, you authorize us to use and disclose your personal information to third parties for validation purposes. This may involve transferring information outside your country if necessary. Security reviews may include verifying the information you provide against third-party databases, such as credit reports. To assist with these checks, you agree to provide any additional information or documentation we may request.
              </p>
            </div>
            <div className="space-y-[20px]">
              <h3 className="text-[28px] text-[#fff] font-[700]">
              SECURITY
              </h3>
              <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">Security is a top priority at Dreamcars. We understand the importance of protecting your information. All personal information you provide is encrypted and stored within a secure network protected by robust firewalls (our services utilize SSL Version 3 with 128-bit encryption for added security). We also hold our subsidiaries, agents, affiliates, and suppliers to high standards regarding data security measures.
              </p>
            </div>
            <div className="space-y-[20px]">
              <h3 className="text-[28px] text-[#fff] font-[700]">
              PROTECTION OF MINORS 
              </h3>
              <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">Dreamcars welcomes users 18 and over (or the legal age in your jurisdiction). By using our services, you verify that you meet this age requirement. We actively seek out and prevent attempts by minors to access our platform. If we discover a minor has tried to provide personal information, we will reject that information and delete it from our records.
              </p>
            </div>
            <div className="space-y-[20px]">
              <h3 className="text-[28px] text-[#fff] font-[700]">
              INTERNATIONAL TRANSFERS
              </h3>
              <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">
Your information on Dreamcars may be stored and processed in various countries where we or our partners, suppliers, or agents operate. By using our platform, you consent to this international data transfer, which may involve countries with different privacy laws. However, we ensure that our partners adhere to our high privacy standards regardless of their location.
              </p>
            </div>
            <div className="space-y-[20px]">
              <h3 className="text-[28px] text-[#fff] font-[700]">
              THIRD-PARTY PRACTICES
              </h3>
              <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">While Dreamcars safeguards your information, we cannot guarantee the protection of any data you provide to external websites linked to our platform, or information collected by third-party affiliates or programs (if applicable). These third-party sites operate independently and have their own privacy policies, which govern how they collect and handle your information.
              </p>
            </div>
            <div className="space-y-[20px]">
              <h3 className="text-[28px] text-[#fff] font-[700]">
              LEGAL DISCLAIMER
              </h3>
              <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">Dreamcars provides our services "as-is" and "as-available," without any guarantees or liability. This means we cannot be held responsible for unforeseen circumstances beyond our control. Due to the ever-evolving nature of technology and our business, we cannot guarantee perfect performance regarding your information privacy. We are also not liable for any indirect, incidental, consequential, or punitive damages related to the use or disclosure of your personal information.
              </p>
            </div>
            <div className="space-y-[20px]">
              <h3 className="text-[28px] text-[#fff] font-[700]">
              CONSENT TO PRIVACY POLICY
              </h3>
              <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">By using Dreamcars, you agree to our Privacy Policy. This policy supersedes all previous versions and should be read alongside our Terms and Conditions and any additional terms displayed on our platforms. We may occasionally update this policy and will notify you by posting the revised terms on the platform. Continued use of Dreamcars after any changes signifies your acceptance of those changes. We recommend reviewing this Privacy Policy periodically for updates. <br /><br />

For your reference, under Article 77 of the GDPR, you have the right to lodge a complaint regarding your data processing with a supervisory authority. This can be done in the EU member state where you live, work, or where you believe a data breach has occurred.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;
