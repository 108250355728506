import logo from './logo.svg';
import Layout from './layout';
import i18n from 'i18next';
import { useMoralis } from 'react-moralis';
import { Routes, Route, Navigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Navbar from './layout/Navbar';
import Footer from './layout/Footer/Footer';


function App() {

  const handelClick = (lang) => {
    i18n.changeLanguage(lang);
    console.log(handelClick, "sdj")
  }
  return (
    <div className="">
      <div className='mb-[4rem] z-[99] w-[100%] fixed xs:mb-[3rem] sm:mb-[3rem] md:mb-[3rem]'>
        <Navbar handelClick={handelClick} className="" />
      </div>
      <Layout />
      <div>
        <Footer className="bg-[#111010]" />
      </div>
    </div>
  );
}

export default App;
