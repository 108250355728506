import React, { useEffect, useState } from "react";
import usimg from "../../src/assects/images/usimg.png";
import attach from "../../src/assects/images/attachus.png";

function Contactus() {
  useEffect(() => {
    window.scrollTo(0, 0);
    const handleScroll = () => {
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const [content, setContent] = useState("");
  const [subject, setSubject] = useState("");
  return (
    <>
      <div className="backgroundgrupside pt-[7rem] pb-[4rem]">
        <div className="w-[90%] space-y-[42px] max-w-[1440px] mx-auto ">
          <div className="flex items-center px-[1rem] space-x-4">
            <img className="h-[100%]" src={usimg} alt="" />
            <h3 className="text-[38px] font-[700] text-[#fff]">Contact us</h3>
          </div>
          <div className="contactbggr space-y-[15px] px-[50px] py-[60px] max-w-[1170px] w-[100%] mx-auto">
            <div className="space-y-[10px]">
              <label className="text-[#FFF] text-[16px] font-[700]" htmlFor="">
                Name:{" "}
                <span className="text-[#F00] text-[16px] font-[700] ">*</span>
              </label>
              <input
                type="text"
                placeholder="Your name"
                className="text-[#fff] px-2 text-[16px] h-[40px] rounded-[5px] outline-none w-[100%] border border-[#ffffff99] bg-[transparent]"
              />
            </div>
            <div className="space-y-[10px]">
              <label className="text-[#FFF] text-[16px] font-[700]" htmlFor="">
                Email:{" "}
                <span className="text-[#F00] text-[16px] font-[700] ">*</span>
              </label>
              <input
                type="email"
                placeholder="Your email"
                className="text-[#fff] px-2 text-[16px] h-[40px] rounded-[5px] outline-none w-[100%] border border-[#ffffff99] bg-[transparent]"
              />
            </div>
            <div className="space-y-[10px]">
              <label className="text-[#FFF] text-[16px] font-[700]" htmlFor="">
                Subject:{" "}
                <span className="text-[#F00] text-[16px] font-[700] ">*</span>
              </label>
              <input
                type="text"
                placeholder="Ticket subject"
                onChange={(e) => setSubject(e.target.value)}
                className="text-[#fff] px-2 text-[16px] h-[40px] rounded-[5px] outline-none w-[100%] border border-[#ffffff99] bg-[transparent]"
              />
            </div>
            <div className="space-y-[10px]">
              <label className="text-[#FFF] text-[16px] font-[700]" htmlFor="">
                Description:{" "}
                <span className="text-[#F00] text-[16px] font-[700] ">*</span>
              </label>
              <textarea
                type="text"
                onChange={(e) => setContent(e.target.value)}
                placeholder="Please describe your problem in detail and attach a screenshot, photo or video, if necessary."
                className="text-[#fff] h-[107.232px] px-2 text-[16px]  rounded-[5px] outline-none w-[100%] border border-[#ffffff99] bg-[transparent]"
              />
            </div>
            <div className="flex space-x-4">
              <a href={`mailto:no-one@snai1mai1.com?subject=` + subject + `&body=` + content}>tell a friend
                <button className="w-[177px] h-[41px] bg-[#FFD02F] rounded-[6.37px] text-[16px] font-[700]">
                  Submit Request
                </button>
              </a>
              <button className="w-[177px] h-[41px] bg-[#414141] rounded-[6.37px]  text-[16px] font-[700] text-[#fff]">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contactus;
