import React from "react";
import { useTranslation } from "react-i18next";

import licimg1 from "../../assects/images/licimg (1).png";
import licimg2 from "../../assects/images/licimg (2).png";
import licimg3 from "../../assects/images/licimg (3).png";
import Button from "../../components/Button/button";

const OWnCarSection = () => {
  const { t } = useTranslation();

  return (
    <div className="Carbgimg ">
      <div className="w-[90%] max-w-[1440px] space-y-[2rem] xs:space-y-[1rem] py-[4.5rem] xs:py-[2rem] mx-auto ">
        <div>
          <h3 className="text-[46px] xs:text-[24px] xs:text-center font-[700] leading-[130%] text-[#fff]">Own Luxury Cars,<br /> Earn Daily Rewards</h3>
        </div>
        <div className="space-y-[25px] xs:space-y-[10px] max-w-[525px] xs:max-w-[100%] w-[100%]">
            <p className="text-[18px] xs:text-[18px] xs:text-center font-[400] leading-[130%] text-white">Dreamcars introduces a new concept: fractional ownership of luxury rental cars, all thanks to blockchain technology. With Dreamcars, you can buy fractions of prestigious cars like Mercedes-Benz, Porsche, Bentley, Rolls Royce, Ferrari, Lamborghini, and more using our digital currency, $DCARS. These cars are part of the Dreamcars Rental Car fleet, ensuring that you not only owning a piece of the car but also earn income through rentals. </p>
            <p className="text-[18px] xs:text-[18px] xs:text-center font-[400] leading-[130%] text-white">Every Dreamcars NFT is backed by a physical car, fully insured, and available in one of our rental car showrooms. These cars are then divided into fractions, starting at as little as $10.</p>
            <p className="text-[18px] xs:text-[18px] xs:text-center font-[400] leading-[130%] text-white">No more spending hundreds of thousands on a single car and losing money due to depreciation each year. With Dreamcars, your car becomes a valuable asset, providing you an additional source 
of income with positive monthly cash flow. </p>
        </div>
        <div className="xs:flex xs:justify-center ">
                        <Button link="#hero" text={`${t('BetaSection.btn')}`} classes="font-[700] bgcolor w-[169px] py-[12px] xs:text-[14px] rounded-[10px]" />
                    </div>
      </div>
    </div>
  );
};
export default OWnCarSection;
