import React, {useEffect} from "react";

function TokenSale() {
    useEffect(() => {
        window.scrollTo(0, 0);
        const handleScroll = () => {
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, []);
  return (
    <>
      <div className="pt-[7rem] bg-[#1F1F1F] pb-[4rem]">
       <div className="relative">
       <div className="w-[90%] space-y-[42px] max-w-[1440px] mx-auto ">
          <div className="space-y-[20px]">
            <h3 className="text-[46px] text-[#fff] font-[700] ">
              TOKEN SALE AGREEMENT
            </h3>
            <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">
              THIS DOCUMENT IS NOT A SOLICITATION FOR INVESTMENT AND DOES NOT
              CONSTITUTE AN OFFER OF DIGITAL CURRENCY, COMMODITY, SECURITY,
              FINANCIAL INSTRUMENT OR ANY OTHER FORM OF INVESTMENT, SECURITIES
              TO THE PUBLIC OR A COLLECTIVE INVESTMENT SCHEME, NOR DOES IT
              REQUIRE REGISTRATION OR APPROVAL FROM A REGULATORY AUTHORITY IN
              ANY JURISDICTION.
            </p>
          </div>
          <div className="space-y-[20px] ">
            <div className="space-y-[20px]">
              <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">
                This Agreement is part of a reward-based DCARS Token
                Distribution Event, and no financial instruments are issued to
                the Participants of this Event. DCARS Tokens are not securities
                and do not carry with them any rights as may be commonly
                associated with securities and this Agreement, its Annexes, or
                any other documents which are an integral part of the Agreement
                do not constitute an offer of securities to the public.
              </p>
            </div>
            <div className="space-y-[20px]">
              <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">
                If you wish to purchase DCARS Tokens, you must not understand,
                interpret, classify or treat. DCARS Tokens as (1) currencies;
                (2) bonds and stocks issued by any institution (3) units of
                securities or derivatives such as collective investment schemes
                and business trusts (4) rights under contracts for difference
                and other contracts whose purpose is or is impersonated for the
                purpose of guaranteeing a return on investment or avoiding
                losses (5) rights, options, and derivatives in these bonds and
                stocks issued by any institution.
              </p>
            </div>
            <div className="space-y-[20px]">
              <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">
                THIS TOKEN SALE AGREEMENT (the “Agreement” and/or “Token
                Distribution Event”) is made as of today
              </p>
            </div>
            <div className="space-y-[20px]">
              <p className="2xl:text-[20px] xl:text-[20px] lg:text-[20px] md:text-[20px] sm:text-[20px] text-[14px] font-[700] text-[#fff]">
                BETWEEN
              </p>
            </div>
            <div className="space-y-[20px]">
              <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">
                Dreamcars Marketing LTD, a corporate entity associated with the
                Presale.dreamcars.platform, (Hereinafter referred to as the
                “Company”).
              </p>
            </div>
            <div className="space-y-[20px]">
              <p className="2xl:text-[20px] xl:text-[20px] lg:text-[20px] md:text-[20px] sm:text-[20px] text-[14px] font-[700] text-[#fff]">
                AND
              </p>
            </div>
            <div className="space-y-[20px]">
              <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">
                Any person (natural or juridical), who intends to participate in
                and contribute towards, this public reward-based Token
                Distribution Event and become a Participant (hereinafter
                referred to as the “Participant”) (Hereinafter singly referred
                to as the ‘Party’ and collectively referred to as the ‘Parties’)
              </p>
            </div>
            <div className="bggr1"></div>
            <div className="bggr2"></div>

            <div className="space-y-[20px]">
              <h3 className="text-[20px] text-[#fff] font-[700]">
                WHEREAS,{" "}
                <span className="font-[400]">
                  DreamCars Ltd. (the “Company”) intends to accept contributions
                  through a Token Distribution Event (TDE), in exchange for an
                  allocation of Tokens up to an amount not exceeding the Maximum
                  Token Amount, and during the Token Distribution Event Period.
                </span>
              </h3>
              <h3 className="text-[20px] text-[#fff] font-[700]">
                WHEREAS,{" "}
                <span className="font-[400]">
                  {" "}
                  the terms of the TDE and other information and details of the
                  Token and the Project are outlined on the Website and in the
                  Whitepaper.
                </span>
              </h3>
              <h3 className="text-[20px] text-[#fff] font-[700]">
                WHEREAS,{" "}
                <span className="font-[400]">
                  the Company declares that, having taken all reasonable care to
                  ensure that such is the case, the information contained in
                  this Agreement and on the Website, to the best of its
                  knowledge, is in accordance with the facts and contains no
                  omission likely to affect a Participant’s decision to
                  participate in the Distribution Event or not.
                </span>
              </h3>
              <h3 className="text-[20px] text-[#fff] font-[700]">
                WHEREAS,{" "}
                <span className="font-[400]">
                  {" "}
                  the Distribution Event applies only to Participants who
                  confirm that they are not Prohibited Participants.
                </span>
              </h3>
              <h3 className="text-[20px] text-[#fff] font-[700]">
                WHEREAS,{" "}
                <span className="font-[400]">
                  the Company intends only to accept contributions in the Coins
                  or Tokens denoted on the web page for the Tokens unless
                  otherwise decided at the discretion of the Company.
                </span>
              </h3>
              <h3 className="text-[20px] text-[#fff] font-[700]">
                WHEREAS,{" "}
                <span className="font-[400]">
                  the Company and the Participant desire to enter into a
                  relationship in which the Participant shall contribute towards
                  the Project, and the Company shall allocate the Tokens to be
                  used on the Platform, developed by the Company.
                </span>
              </h3>
              <h3 className="text-[20px] text-[#fff] font-[700]">
                WHEREAS,{" "}
                <span className="font-[400]">
                  Tokens are linked to the use of the Platform and are not
                  designed or disingenuously devised to acquire shares or
                  security/ies or equivalent rights, intellectual property
                  rights or any other form of participation relating to the
                  Company or money, or any expectation of profits.
                </span>
              </h3>
              <h3 className="text-[20px] text-[#fff] font-[700]">
                NOW, THEREFORE,{" "}
                <span className="font-[700]">
                  {" "}
                  in consideration of the mutual agreements herein contained,
                  and intending to be legally bound, the parties agree as
                  follows:
                </span>
              </h3>
            </div>
            <div className="space-y-[20px]">
              <h3 className="text-[28px] text-[#fff] font-[700]">
                1. Definitions and Interpretation
              </h3>
              <div className="space-y-[10px]">
                <h3 className="text-[20px] text-[#fff] font-[700]">
                  DCARS Token  <br />
                  <span className="font-[400]">
                    The token created and issued by Dreamcars LTD. The Company
                    may be deemed to include its subsidiary/ies, parent/s and/or
                    other related parties who will be contributing to the
                    development and completion of the Project.
                  </span>
                </h3>
                <h3 className="text-[20px] text-[#fff] font-[700]">
                  The Platform <br />
                  <span className="font-[400]">
                     A decentralized platform that the Company is developing and
                    owns, operating on the basis of blockchain technology and
                    smart contracts
                  </span>
                </h3>
                <h3 className="text-[20px] text-[#fff] font-[700]">
                  Dissolution Event <br />
                  <span className="font-[400]">
                    An event whereby it becomes necessary, recommendable or less
                    disadvantageous for the Company to dissolve or liquidate.
                  </span>
                </h3>
                <h3 className="text-[20px] text-[#fff] font-[700]">
                  Exchange Rate <br />
                  <span className="font-[400]">
                    Means (i) with respect to USDT the relative ETH/USD or
                    BNB/USD exchange rate displayed as an estimate to the
                    Participant prior to the contribution and calculated by the
                    Company on the basis of an averaged USDT/USD exchange rate
                    which shall be acquired from multiple independent sources at
                    the time of the contribution and (ii) with respect to other
                    means of payment, the exchange rate to be used shall be
                    determined by the Company the same way and agreed with the
                    Participant.
                  </span>
                </h3>
                <h3 className="text-[20px] text-[#fff] font-[700]">
                  Maximum Token Amount <br />
                  <span className="font-[400]">
                    The total amount of Tokens available for allocation during
                    the Distribution Event in exchange for contribution received
                    which contribution shall not exceed the maximum of
                    480’000’000 DCARS.
                  </span>
                </h3>
                <h3 className="text-[20px] text-[#fff] font-[700]">
                  Minimum Contribution
                  <br />
                  <span className="font-[400]">
                    There is no minimum contribution from anyone Participant
                    during the Token Distribution Event, but the Company
                    reserves the right to introduce a minimum contribution at a
                    later stage. A contribution less than a possible minimum
                    contribution may at the Company’s discretion is not
                    accepted. Participant refers to any person (natural or
                    juridical), who intends to participate in the Token
                    Distribution Event and acquire and hold Tokens and become a
                    Participant under this Token Distribution Event Agreement.
                  </span>
                </h3>
                <h3 className="text-[20px] text-[#fff] font-[700]">
                Participation Amount
                  <br />
                  <span className="font-[400]">
The amount in USDT or ETH or other accepted currency contributed by a Participant subject to the USD exchange rate.
                  </span>
                </h3>
                <h3 className="text-[20px] text-[#fff] font-[700]">
                Price
                  <br />
                  <span className="font-[400]">
                  Note: The final price is determined at 1 DCARS = 0.0714 USD. All Participants will have the same token price.
                  </span>
                </h3>
                <h3 className="text-[20px] text-[#fff] font-[700]">
                Prohibited Participant
                  <br />
                  <span className="font-[400]">
                  
(i) a natural person wishing to become a Participant and being a citizen, national, resident or having a similar connecting factor to; or
(ii) a juridical person wishing to become a Participant and being incorporated, registered or effectively managed and controlled form or in: a country, jurisdiction or territory w here the Token Distribution Event or the holding and use, of Tokens and/or virtual currencies or other tokens at any other moment in time is prohibited by laws, regulations or other practices and policies in the said country, jurisdiction or territory, which is taken to include, but is not limited to the US or any other jurisdictions where the aforementioned are prohibited. This shall include any person representing or acting on behalf of such Prohibited Participant/s in any manner or capacity whether openly or covertly.
                  </span>
                </h3>
                <h3 className="text-[20px] text-[#fff] font-[700]">
                Project
                  <br />
                  <span className="font-[400]">
                 
The Project being the reason behind the Token Distribution Event with the aim of creating the Platform into which Token/s shall be utilized as explained on the Website.
                  </span>
                </h3>
                <h3 className="text-[20px] text-[#fff] font-[700]">
                Terms
                  <br />
                  <span className="font-[400]">
The Terms of Token Distribution Event as may be amended from time to time, which shall govern the purchase and use of Tokens and the Platform
                  </span>
                </h3>
                <h3 className="text-[20px] text-[#fff] font-[700]">
                Token
                  <br />
                  <span className="font-[400]">
A utility cryptographic decentralized token named “DCARS” based on the platform intended primarily for the use of, access or participation to the Platform as outlined on the Website. DCARS is a reward and loyalty token for the Dreamcars owners and guarantees only rental earnings. The proprietor of Dreamcars shall bear no claim of ownership, entitlement, or equity in any enterprise affiliated with Dreamcars. DCARS shall not be considered a security and shall not represent any form of investment.
                  </span>
                </h3>
                <h3 className="text-[20px] text-[#fff] font-[700]">
                Token Distribution Event 
                  <br />
                  <span className="font-[400]">
The offer made by the Company, through ten Phases to a public reward-based marketing and fundraising event, to accept contributions towards the Project, which contributions will be rewarded, with an allocation of a determinate number of Tokens, in the Private Sale of the Token, to participants according to the terms of this Agreement. The Token Distribution Event will be conducted on the project's web page.
                  </span>
                </h3>
                <h3 className="text-[20px] text-[#fff] font-[700]">
                Token Distribution Event Period
                  <br />
                  <span className="font-[400]">
The Token Distribution Event Period starts on the date and time set to be announced on the website and continues for a period of up to three (3) months* or the moment when the Maximum Token Amount has been reached, whichever occurs earlier.
                  </span>
                </h3>
                <h3 className="text-[20px] text-[#fff] font-[700]">
                Total Token Supply
                  <br />
                  <span className="font-[400]">
The total amount of Token supplied shall be 1 billion (1,000,000,000); where a total of (480,000,000) are to be sold in the Community Event (Maximum Token Amount).
                  </span>
                </h3>
                <h3 className="text-[20px] text-[#fff] font-[700]">
                Wallet
                  <br />
                  <span className="font-[400]">
A private key, or a combination of private keys linked to an Ethereum-based digital wallet having a unique address and capable of accepting ERC-20 and the DCARS Token and which is necessary to acquire, hold and dispose of the DCARS Token.
                  </span>
                </h3>
                <h3 className="text-[20px] text-[#fff] font-[700]">
                Website
                  <br />
                  <span className="font-[400]">
The website linked to the domain www.presale.dreamcars and all subdomains of such websites or any other website as indicated by the Company from time to time.
                  </span>
                </h3>
                <h3 className="text-[20px] text-[#fff] font-[700]">
                Terms and context
                  <br />
                  <span className="font-[400]">
The reference to the term’s “country”, “jurisdiction” and “territory” may be used interchangeably within this Agreement and shall have the same meaning and shall also be taken to include any determinate geographic location to the extent applicable in this Agreement.
                  </span>
                </h3>
                <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">The reference to the term “holding” used with respect to the holding of Token shall be construed to include holding in any manner including but not limited to ‘ownership’ and ‘possession’, whether in the Participant’s own name or on behalf of others. The term “use” with respect to the use of Token shall be construed to include ‘trade’, ‘barter’, ‘exchange’ or ‘utilizing’ of Token in any other manner, whether in the Participant’s own name or on behalf of others.
                </p>
                <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">The term “Wallet” shall be construed to include ‘digital Vault’ or other storage mechanism and these terms may be used interchangeably within this Agreement. The terms “you”, “your”, “he”, “contributor” and “participant” may be used interchangeably within this Agreement and shall have the same meaning as the definition of Participant above.
                </p>
                <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">The terms “we”, “us”, “our” and “company” may be used interchangeably within this agreement and shall have the same meaning as the definition of Company above. The headings in this Agreement are inserted for convenience only and shall not affect its construction.
                </p>
                <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">Where the context so requires, the use of the masculine gender shall include the feminine and/or neuter genders and the singular shall include the plural, and vice versa.
                </p>
              </div>
            </div>

            <div className="space-y-[20px]">
              <h3 className="text-[28px] text-[#fff] font-[700]">
              2. The Process and The Sale Agreement
              </h3>
              <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">The Participation process, detailed on the Website, will involve the Company accepting contributions from a Participant during the Token Distribution Event. The Company shall not be obliged to accept contributions that are less than the Minimum Contribution and shall also not be obliged to accept contributions from Participants who do not provide the documents necessary. Acceptance to this Agreement, the Terms and any other documents made via the Website together with the contribution made by the Participant shall be legally binding on the Participant. Therefore, to the extent that the Company refuses to accept the Contribution as outlined in this Agreement, the contribution shall be nonrefundable. The Company intends only to accept contributions in ETH, USDT and BNB for the Tokens. The Company reserves the right to accept other or additional payment methods and currencies.
                </p>
                <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">Transfer of Tokens <br /> Tokens are issued at the sole discretion of the Company on submission of the requisite participation Amount, and satisfaction of the following cumulative conditions: (a) the participant has accepted via the Website, the term of this Agreement, its Annexes and any other documents forming part of the Contract; (b) the Participant has transferred the contribution which the Company has confirmed receipt of; (c) The Contribution satisfied the
                </p>
                <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">Minimum Contribution, during the Token Distribution Event, unless otherwise accepted by the Company; (d) the Company has received, is satisfied with, the documentation requested in Annex I; (e) the Company has no reason to believe that the Representations made in Annex II are incorrect or false; After all the above cumulative conditions are fulfilled, the transfer of Tokens shall be made electronically to the designated Wallet, within a reasonable time after the Token Distribution Event Period ends. Where any of the above cumulative conditions has not been satisfied by the Participant, the Company shall request immediately the Participant to rectify the situation and satisfy the said conditions. Failure to do so within a reasonable time, the Company shall reserve the right to return the contribution to the Participant. The Tokens shall not be transferable until the Token. The event period ends, and all Maximum Token Amount has been allocated and distributed.
                </p>
            </div>
            <div className="space-y-[20px]">
              <h3 className="text-[28px] text-[#fff] font-[700]">3. Participation Principles </h3>
              <div className="space-y-[10px]">
                <h3 className="text-[20px] text-[#fff] font-[700]">
                3.1. <span className="font-[400]">
                 The Participant is not eligible to acquire any Tokens if the Participant is a Prohibited. Participant as defined above. Tokens are not available to any Prohibited Participant and the Company retains the right to refuse to transfer the Tokens to any Prohibited Participant.
                  </span>
                </h3>
                <h3 className="text-[20px] text-[#fff] font-[700]">
                3.2. <span className="font-[400]">The Participant understands and agrees that it is his obligation to ensure compliance with any legislation relevant to his country of residence or domicile concerning the acquisition of Tokens. The Participant also represents and warrants that to the extent that he is not a Prohibited Participant, it is solely up to him to inform himself and ensure that no prior or subsequent approval, notification, registration or license is needed or if such is needed it is solely up to him to obtain such prior or subsequent approval, notification, registration or license
                  </span>
                </h3>
                <h3 className="text-[20px] text-[#fff] font-[700]">
                3.3.<span className="font-[400]">All Tokens allocated from the Company are final, and there are no refunds or cancellations except as may be required by applicable law, decree, regulation, treaty, or administrative act and/or as set forth in Clause 4 of this Agreement. The Company reserves the right to refuse or cancel the acquisition of Tokens at any time at its sole discretion. 
                  </span>
                </h3>
                <h3 className="text-[20px] text-[#fff] font-[700]">
                3.4. <span className="font-[400]">The Participant confirms his understanding that Tokens are not securities and do not carry with them any rights as may be commonly associated with securities. Tokens do not grant any rights with respect to corporate decision-making. Also, Tokens do not grant a right to dividends, votes or proceeds upon liquidation or any other right to payment from the Company. Tokens are intended solely to be used on the Platform. Rights of the Participant in this Token Community Event are limited to statutory and contractual rights according to Law.
                  </span>
                </h3>
                <h3 className="text-[20px] text-[#fff] font-[700]">
                3.5. <span className="font-[400]">The Participant confirms his understanding that the Company retains all rights, title and interest in all of its intellectual property, including inventions, discoveries, processes, marks, methods, compositions, formulae, techniques, information, source code, brand names, graphics, user interface design, text, logos, images, information and data pertaining to the Website, the Project and Token whether or not patentable, copyrightable or protectable in trademark, and any trademarks, copyrights or patents based thereon. A Participant may not use any of the Company’s intellectual property for any reason, except with the Company’s express, prior, written consent.
                  </span>
                </h3>
                <h3 className="text-[20px] text-[#fff] font-[700]">
                3.6. <span className="font-[400]">Acquiring Tokens by the Participant in no way creates any exclusive relationship between the Participant and the Company, nor any partnership, joint venture, employment, or agency.
                  </span>
                </h3>
                <h3 className="text-[20px] text-[#fff] font-[700]">
                3.7. <span className="font-[400]">The Participant confirms his understanding that the Company shall issue Tokens once the Token Distribution Event is closed; and only if documentation requested in Annex II and other verifications have been passed and approved by the Company at its sole discretion.
                  </span>
                </h3>
              </div>
            </div>
            <div className="space-y-[20px]">
              <h3 className="text-[28px] text-[#fff] font-[700]">
              4. Dissolution Event
              </h3>
              <div className="space-y-[10px]">
                <h3 className="text-[20px] text-[#fff] font-[700]">
                4.1 <span className="font-[400]">In the case of a Dissolution Event before the Token Distribution Event end date, the Company will refund an amount equal to the Participation Amount and payable to the Participant immediately prior to, or concurrent with, the consummation of the Dissolution Event, subject to rights and privileges of creditors under law.
                  </span>
                </h3>
                <h3 className="text-[20px] text-[#fff] font-[700]">
                4.2. <span className="font-[400]">If immediately prior to the consummation of the Dissolution Event, the assets of the Company that remain legally available for distribution to the Participant and all participants entering this Agreement separately, as determined in good faith by the Company’s board of directors, are insufficient to permit the payment to all Participants of their respective Participation Amounts, then the remaining assets of the Company legally available for distribution, following all distributions to the shareholders and creditors, will be distributed with equal priority and pro-rata among the Participants in proportion to their Participation Amounts
                  </span>
                </h3>
              </div>
              <div className="space-y-[20px]">
                <h3 className="text-[20px] text-[#fff] font-[700]">
                5. Termination
                </h3>
                <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">
                    This Agreement will expire and terminate upon the earlier of (i) the allocation of Tokens to the Participant according to Clause 2 or refund of the amount of Participation Amount to Participant as the case may be; or (ii) the payment, or setting aside for payment, of amounts due to the Participant according to Clause 4; All provisions of the Terms which by their nature should survive termination, shall survive termination, including but not limited to, disclaimers or limitations of obligations or liability and indemnity
                    </p>
              </div>
              <div className="space-y-[20px]">
                <h3 className="text-[20px] text-[#fff] font-[700]">
                6. Representations and Warranties of the Company
                </h3>
                <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">
                The Company hereby represents and warrants to the Participant that: a. The Company is a company duly organized and validly existing under the laws of the jurisdiction of its incorporation and has the power to own and lease its properties and to carry on its business as now being conducted and as presently proposed to be conducted. b. The Company has the legal right and full power and authority to enter, execute, deliver and perform their respective obligations under the Agreement.
                    </p>
                <div className="space-y-[10px]">
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  c.<span className="font-[400]">
                   the execution and performance of this Agreement by the Company have been duly authorized by all necessary actions of the Company, and this Agreement has been duly executed and delivered by the Company.
                    </span>
                  </h3>
                  <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">d. The execution and performance of the Agreement does not and will not breach any agreement or obligation by which they are bound or will not violate or infringe any applicable law or tax regulations.
                    </p>
                    <h3 className="text-[20px] text-[#fff] font-[700]">
                    e. <span className="font-[400]">The Agreement, once executed, will constitute legal, valid, binding, and enforceable obligations towards the Parties
                    </span>
                  </h3>
                </div>
              </div>
              <div className="space-y-[20px]">
                <h3 className="text-[20px] text-[#fff] font-[700]">
                7. Representations and Warranties of the Participant
                </h3>
                <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">The Participant hereby represents and warrants to the Company that:
                    </p>
                <div className="space-y-[10px]">
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  a. <span className="font-[400]">
                    The Participant is an individual or a legal entity duly organized and validly existing under the laws of the jurisdiction of its incorporation.
                    </span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  b. <span className="font-[400]">The Participant has the legal right and full power and authority to enter into, execute, deliver and perform their respective obligations under the Agreement. 
                    </span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  c. <span className="font-[400]">The execution and performance of this Agreement by the Company have been duly authorized by all necessary actions of the Company, and this Agreement has been duly executed and delivered by the Company. 
                    </span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  d. <span className="font-[400]">The execution and performance of the Agreement does not and will not breach any agreement or obligation by which they are bound or will not violate or infringe any applicable law or tax regulations. 
                    </span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  e. <span className="font-[400]">The Participation Amount is provided on Participant’s own account, not as a nominee or agent, and not with a view to assigning any part thereof, and Participant has no present intention of selling, granting any participation in, or otherwise distributing any interest the Participant has under with respect to the Participation Amount or otherwise in connection with this Agreement. 
                    </span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  f. <span className="font-[400]">Participant does not have any contract, undertaking, agreement or arrangement with any person to sell, transfer or grant participation to such person or to any third person, with respect to this Agreement and/or the Participation Amount. 
                    </span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  g. <span className="font-[400]">Participant is a Participant in a Project in its development stage and acknowledges that it can fend for itself, can bear the economic risk of its acquisition of Token, and has such knowledge and experience in financial or business matters that it can evaluate the merits and risks of this Agreement. 
                    </span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  h. <span className="font-[400]">The Participant has such knowledge and experience and sophistication in financial, tax, business, and technology matters as to enable the Participant to evaluate the legal, economic, and other merits and risks associated with this Agreement and the transactions contemplated thereby, including, but not limited to, subscribing for Token,and to make an informed decision with respect thereto. Without prejudice to the foregoing, the Participant hereby represents and warrants to the Company that he has carefully reviewed and understands and accepts the various risks of entering into this Agreement, including the Participant’s possible participation in the Distribution Event and the risks associated with subscribing for Token. The Participant hereby consents and agrees to bear such risks.
                    </span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  i. <span className="font-[400]">The Participant hereby warrants that he is responsible for determining what taxes shall be applied including, for example, Distribution Event, use, value-added, and similar taxes. Any amount that the Participant pays for Tokens is exclusive of all applicable taxes. It is also the Participant’s responsibility to withhold, collect, report, and remit the possible taxes to the appropriate tax authorities. The Company is not responsible for withholding, collecting, reporting, or remitting any Distribution Event, use, value-added, or similar tax arising from the purchasing of Tokens.
                    </span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  j. <span className="font-[400]">The Participant understands and agrees that the Tokens have no rights, uses or attributes, purposes, functionalities, or features, express or implied, including without limitation any uses, purposes, attributes, functionalities or features except those that are provided by Platform and as explained on the Webpage. 
                    </span>
                  </h3>
                  <h3 className="text-[20px] text-[#fff] font-[700]">
                  k. <span className="font-[400]">The Participant furthermore warrants to the Representations made in Annex II.
                    </span>
                  </h3>
                </div>
              </div>
              <div className="space-y-[20px]">
                <h3 className="text-[20px] text-[#fff] font-[700]">
                8. Taxes & Indemnity 
                </h3>
                <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">The Participant shall be responsible to pay all applicable taxes and duties, if any, that may arise in connection with its acquisition under this Agreement. Buyer will defend, indemnify, and hold harmless the Company, its directors, officers, members, employees, agents, attorneys, representatives, affiliates and associates from any claims, damages, losses, liabilities, penalties, fines, costs and expenses arising out of or relating to any third-party claim concerning this agreement, including without limitation any claims related to taxes and duties mentioned.
                    </p>
              </div>
            </div>
            <div className="space-y-[20px]">
              <h3 className="text-[28px] text-[#fff] font-[700]">
              9. Disclaimers: Limitation of Liability
              </h3>
              <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">THE ACQUISITION OF TOKENS UNDER THIS AGREEMENT, THE USE OF TOKENS AND THE PLATFORM ARE PROVIDED ON AN ‘AS IS’ AND ‘AS AVAILABLE’ BASIS. THE COMPANY AND ITS AFFILIATES MAKE NO REPRESENTATIONS OF ANY KIND, EXPRESS, IMPLIED, STATUTORY OR OTHERWISE REGARDING THE ACQUISITION OF TOKEN UNDER THIS AGREEMENT, AND THE PLATFORM INCLUDING ANY WARRANTY THAT THE PLATFORM WILL BE UNINTERRUPTED, ERROR-FREE OR FREE OF HARMFUL COMPONENTS OR THAT ANY CONTENT WILL BE SECURE OR NOT OTHERWISE LOST OR DAMAGED. EXCEPT TO THE EXTENT PROHIBITED BY LAW, THE COMPANY, ITS DIRECTORS, OFFICERS, MEMBERS, EMPLOYEES, AGENTS, ATTORNEYS, REPRESENTATIVES, AFFILIATES AND ASSOCIATES DO NOT ACCEPT ANY LIABILITY FOR ANY DAMAGE OR LOSS, INCLUDING LOSS OF BUSINESS, REVENUE, OR PROFITS, OR LOSS OF OR DAMAGE TO DATA, EQUIPMENT, OR SOFTWARE (DIRECT, INDIRECT, PUNITIVE, ACTUAL, CONSEQUENTIAL, INCIDENTAL, SPECIAL, EXEMPLARY OR OTHERWISE), RESULTING FROM ANY USE OF, OR INABILITY TO USE TOKENS, THE PLATFORM OR THE MATERIAL, INFORMATION, SOFTWARE, FACILITIES OR CONTENT ON THE PLATFORM, AS WELL AS FROM ACQUIRING OF TOKENS, REGARDLESS OF THE BASIS, UPON WHICH THE LIABILITY IS CLAIMED. BUYER ASSUMES ALL RISK OF LOSS RESULTING FROM, CONCERNING OR ASSOCIATED WITH RISKS SET FORTH IN THIS AGREEMENT, WHICH ARE HEREBY INCORPORATED BY REFERENCE INTO THIS AGREEMENT.
              </p>
            </div>
            <div className="space-y-[20px]">
              <h3 className="text-[28px] text-[#fff] font-[700]">
              10. Privacy Policy 
              </h3>
              
              <div className="space-y-[10px]">
              <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">10.1 The Company values Participants’ privacy by not requesting any information that is unnecessary for the holding and use of Tokens and/or Platform or to abide by obligations under applicable law. The Company commits itself to do its utmost to ensure that it, the Providers, its directors, officers, members, employees, agents, attorneys, representatives, affiliates and associates shall not disclose to any person, any non-public proprietary, documents or confidential information which we may have obtained as a result of the relationship between the Parties under the Terms except to the extent necessary further to any law, regulations, rules or agreements. 
              </p>
              <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">10.2 Nevertheless, the Company shall be entitled, at any time, to request from the Participant information and/or documentation that the Company and/or the Providers, in their sole discretion, deem fit and necessary to comply with any applicable law or regulation in connection with the holding and using of Tokens and/or Platform. The Participant agrees to provide the Company and/or the Providers with such information promptly upon request, and he acknowledges that the Company and/or the Providers may not provide the Services to the Participant until he provides such requested information and the Company and/or the Providers have determined that it is permissible under applicable law or regulation. The Company and/or the Providers reserve the right to require more due diligence documentation in compliance with any new or amended regulation which may come into force in the future. 
              </p>
              <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">10.3 It is agreed that the terms of the Agreement are subject to the duty of confidentiality on our part. The Company is committed to ensuring confidentiality. The Company commits itself to do its utmost to ensure not disclose to any person, any non-public proprietary, documents or confidential information which the Company may have obtained because of this Agreement for as long as such information remains non-public and unless such disclosure is duly authorized or required in terms of the law. 
              </p>
              <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">10.4 The duty of confidentiality does not extend to communications in furtherance of an illegal purpose nor to documents or facts showing the commission of a crime or fraud after the commencement of this engagement. Moreover, the Company is subject to the duty, (without need to inform the Client) to notify the police if the Company suspects that any property or money represents proceeds of corruption, drug trafficking or other serious crime or belongs to a terrorist or terrorist entity. Furthermore, the Company shall not be liable for any consequences of any disclosure where the Company acts in good faith in accordance with applicable law and you acknowledge that the Company is not bound by confidentiality obligations when disclosure is necessary for the Company’s opinion to protect its own interests.
              </p>
              
              </div>
            </div>
            <div className="space-y-[20px]">
              <h3 className="text-[28px] text-[#fff] font-[700]">
              11. Disclosure of Certain Risk Factors Associated with Tokens 
              </h3>
              <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">The Participant understands that the acquisition of Tokens involves substantial risk,including, without limitation, the following (the terms herein shall have the meaning assigned to them in the Agreement to which this is attached): 
              </p>
              <div className="space-y-[10px]">
                <h3 className="text-[20px] text-[#fff] font-[700]">
                  <span className="font-[400]">a. The Company has no operational history. 
                  </span>
                </h3>
                <h3 className="text-[20px] text-[#fff] font-[700]">
                  <span className="font-[400]">b. The Company maintains sole discretion in the conduct of its business, including as related to its offer, maintenance and use of any Token and the Platform. The Participant will not have the ability to influence the performance or decisions made by the Company. 
                  </span>
                </h3>
                <h3 className="text-[20px] text-[#fff] font-[700]">
                  <span className="font-[400]">c. The acquisition of Tokens is subject to severe constraints on liquidity. At the time of the Token Distribution Event, there is no market for the Tokens and such a market may not develop. The acquisition of Tokens is suitable only for sophisticated and experienced Participants who are financially able to maintain their tokens for an indefinite period of time and who can afford a loss of their entire Participation Amount.
                  </span>
                </h3>
                <div>
                <h3 className="text-[20px] text-[#fff] font-[700]">
                  <span className="font-[400]">d. Acquisition of Tokens is highly volatile and speculative and considered highly risky. In particular, the Participant understands and accepts the inherent risks associated with Tokens, including, but not limited to, risks associated with: 
                  </span>
                </h3>
                <h3 className="text-[20px] text-[#fff] font-[700]">
                  <span className="font-[400]">(a) decentralization of the blockchain technology; 
                  </span>
                </h3>
                <h3 className="text-[20px] text-[#fff] font-[700]">
                  <span className="font-[400]">(b) money laundering;
                  </span>
                </h3>
                <h3 className="text-[20px] text-[#fff] font-[700]">
                  <span className="font-[400]">(c) fraud; 
                  </span>
                </h3>
                <h3 className="text-[20px] text-[#fff] font-[700]">
                  <span className="font-[400]">(d) anonymity of transactions; 
                  </span>
                </h3>
                <h3 className="text-[20px] text-[#fff] font-[700]">
                  <span className="font-[400]">(e) exploitation for illegal purposes; 
                  </span>
                </h3>
                <h3 className="text-[20px] text-[#fff] font-[700]">
                  <span className="font-[400]">(f) theft; 
                  </span>
                </h3>
                <h3 className="text-[20px] text-[#fff] font-[700]">
                  <span className="font-[400]">(g) instability and other flaws of exchanges or brokers/custodians; 
                  </span>
                </h3>
                <h3 className="text-[20px] text-[#fff] font-[700]">
                  <span className="font-[400]">(h) the lack of regulation of tokens as of the date hereof. 
                  </span>
                </h3>
                </div>
                <h3 className="text-[20px] text-[#fff] font-[700]">
                  <span className="font-[400]">e. The Participant understands and accepts that the software and hardware, technology and technical concepts and theories usually used by issuers of Tokens is still in an early development stage and unproven, there is no warranty that the technology will be uninterrupted or error-free and there is an inherent risk that the technology could contain weaknesses, vulnerabilities or bugs causing, inter alia, the complete loss of the Tokens and the Participant’s entire Participation Amount.
                  </span>
                </h3>
                <h3 className="text-[20px] text-[#fff] font-[700]">
                  <span className="font-[400]">f. The Participant understands and accepts that the distribution of a Token and the development of the Project by the Distribution Event of Token, may be abandoned for a number of reasons, including but not limited to failure of the Project, lack of interest of the industry and/or the public, lack of funding, lack of commercial success or prospects (e.g. caused by competing projects) etc. The Participant, therefore, understands that there is no assurance that, even if such a Project is partially or fully developed and launched, the Participant may receive any functionality through the Token held by him, and that they may become worthless. The Participant further understands and accepts that the Project financed by the Distribution Event of Token, may give rise to other, alternative projects, promoted by third parties unaffiliated to the issuer of the Token, under which the Token will have no intrinsic value. 
                  </span>
                </h3>
                <h3 className="text-[20px] text-[#fff] font-[700]">
                  <span className="font-[400]">g. Tokens are usually assessed using a private key that corresponds to the Wallet at which it is stored. The Participant understands and accepts that if the private key or the “seed” used to create the address and corresponding private key got lost or stolen, the obtained Token associated with the Participant’s address might be unrecoverable and will be permanently lost. 
                  </span>
                </h3>
                <h3 className="text-[20px] text-[#fff] font-[700]">
                  <span className="font-[400]">h. The Participant understands and accepts that the underlying software application and software platform of Token, the Platform, the internet, the blockchain network, the Participant, and other involved software, technology components and/or platforms concerned with Token may be exposed to attacks by hackers or other individuals that could result in theft or loss of Token and the Participant’s entire Participation Amount. 
                  </span>
                </h3>
                 <h3 className="text-[20px] text-[#fff] font-[700]">
                  <span className="font-[400]">i. The Participant understands and accepts that public blockchain-based systems depend on independent validators, and therefore may be vulnerable to consensus attacks including, but not limited to, double-spend attacks, majority voting power attacks, race condition attacks and censorship attacks. Any successful attacks present a risk to such a system, expected proper execution and sequencing of Token-related transactions, and expected proper execution and sequencing of software computations, including loss of the Participant’s entire Participation Amount.
                  </span>
                </h3>
              </div>
            </div>
            <div className="space-y-[20px]">
              <h3 className="text-[28px] text-[#fff] font-[700]">
              12. Miscellaneous 
              </h3>
              <div className="space-y-[10px]">
                <h3 className="text-[20px] text-[#fff] font-[700]">
                  <span className="font-[400]">12.1 Repayment of the Participation Amount or distribution of Tokens in accordance with Clauses 4 or 5 above will constitute the full and final discharge of any and all obligations of the Company hereunder and thereupon this Agreement shall terminate and be void in its entirety with no further obligations of the Company. 
                  </span>
                </h3>
                <h3 className="text-[20px] text-[#fff] font-[700]">
                  <span className="font-[400]">12.2 The Company may withhold any amounts required by applicable law from any repayment or conversion of the Participation Amount. The Company may condition any repayment or conversion of the Participation Amount on the Participant satisfying any such withholding obligations.
                  </span>
                </h3>
                <h3 className="text-[20px] text-[#fff] font-[700]">
                  <span className="font-[400]">12.3 Neither this Agreement nor any rights or obligations hereunder may be assigned by the Participant, in whole or in part, without the express prior written consent of the Company. The Company may transfer and assign its rights and obligations hereunder to any other natural or juridical person, to another affiliate, including any entity controlled, controlling or under common control with the Company, to a third party that acquires all or substantially all the assets subject to this Agreement or securities of the Company or any entity into which the Company shall merge or any affiliate thereof. 
                  </span>
                </h3>
                <h3 className="text-[20px] text-[#fff] font-[700]">
                  <span className="font-[400]">12.4 All notices and other communications required or permitted hereunder to be given to a party to this Agreement shall be in writing and shall be sent by fax or email or mailed by registered or certified mail, postage prepaid, or otherwise delivered by hand or by messenger. 
                  </span>
                </h3>
                <h3 className="text-[20px] text-[#fff] font-[700]">
                  <span className="font-[400]">Any notice sent in accordance with this Clause 12 shall be effective  <br />
(i) if mailed, seven (7) days after mailing,  <br />
(ii) if sent by messenger, upon delivery, and  <br />
(iii) if sent via fax or email, on the first business day following transmission accompanied by delivery notification.
                  </span>
                </h3>
              </div>
            </div>
            <div className="space-y-[20px]">
              <h3 className="text-[28px] text-[#fff] font-[700]">
              13. Amendments 
              </h3>
              <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">The Agreement may only be amended by a written document signed by all the contracting Parties. No waiver of any party‘s default or breach of its representations, warranties, covenants, duties, agreements, or obligations or any term or provision of this Agreement, will be effective unless in writing and signed by Parties; any waiver will be limited to the default or breach described therein and no waiver will be or be deemed a waiver of anyother, similar, prior, continuing, or subsequent default or breach. 
              </p>
            </div>
            <div className="space-y-[20px]">
              <h3 className="text-[28px] text-[#fff] font-[700]">
              14. Validity of the Agreement 
              </h3>
              <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">The illegality, invalidity, or unenforceability of any provision of this Agreement decided by the law of another jurisdiction does not affect the legality, validity or enforceability under the law of another jurisdiction nor the legality, validity or enforceability of another provision of this Agreement.
              </p>
            </div>
            <div className="space-y-[20px]">
              <h3 className="text-[28px] text-[#fff] font-[700]">
              15. Applicable Law and Jurisdiction
              </h3>
              <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">This Agreement and any dispute or claim arising out of or in connection with it or its subject matter or formation (including non-contractual disputes or claims) shall be governed by and construed in accordance with the laws. 
              </p>
            </div>
            <div className="space-y-[20px]">
              <h3 className="text-[28px] text-[#fff] font-[700]">
              16. Entire Agreement 
              </h3>
              <p className="2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] font-[400] text-[#fff]">This Agreement embodies the entire agreement between the Parties and supersedes all previous statements, representations and agreements between the Parties relating to the subject matter of this Agreement.
              </p>
            </div>
          </div>
        </div>
       </div>
      </div>
    </>
  );
}

export default TokenSale;
