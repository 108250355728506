import React from "react";
import { useTranslation } from "react-i18next";


import img1 from "../../assects/images/prtnr (2).png"
import img2 from "../../assects/images/prtnr (1).png"
import img3 from "../../assects/images/prtnr (4).png"
import img4 from "../../assects/images/prtnr (3).png"
import { v4 as uuidv4 } from "uuid";

// import img1 from "../../Assets/svgs/image (1).svg"
const imgData = [
    {
        id: uuidv4(),
        src:img1
    },
    {
        id: uuidv4(),
        src:img2
    },
    {
        id: uuidv4(),
        src:img3
    },
    {
        id: uuidv4(),
        src:img4
    },

]

function PoweredBy() {
  const { t } = useTranslation();

  return (
    <>
      <div className=" w-[70%] xs:w-[90%] max-w-[1440px]  py-[3rem] xs:py-[2rem] mx-auto">
        <h2 className="2xl:text-[40px] text-[#fff] xl:text-[40px] lg:text-[40px] md:text-[40px] sm:text-[30px] text-[24px] text-center font-[700] leading-[40px] leading-[130%]  pb-[47px] xs:pb-[15px]">
         {t('PoweredBy.title')}
        </h2>
        <p id="faqs"></p>
        <div className="2xl:flex xl:flex lg:flex md:flex sm:hidden hidden flex-wrap justify-between gap-y-[36px]">
            {
                imgData.map((item,id)=>(
                    <div key={id} className="2xl:w-[25%] xl:w-[25%] lg:w-[25%] md:w-[33%] sm:w-[35%] w-[50%]  flex justify-center">
                        <img className=" h-[70px]" src={item.src} alt="" />
                    </div>
                ))
            }
        </div>
        
        <div className="2xl:hidden xl:hidden lg:hidden md:hidden sm:block block xs:space-y-[15px]">
            <div className="flex justify-center">
                <img className="max-w-[90px] " src={img2} alt="" />
            </div>
            <div className="flex justify-between">
                <img className="max-w-[80px] " src={img1} alt="" />
                <img className="max-w-[80px] " src={img3} alt="" />
                <img className="max-w-[80px] " src={img4} alt="" />
            </div>
        </div>
       
      </div>
    </>
  );
}

export default PoweredBy;
