import React from "react";
import { useTranslation } from "react-i18next";

import Button from "../../components/Button/button";
import mostfun from "../../assects/images/mostfun.png"




const MostFunSection = () => {
    const { t } = useTranslation();

    return (
        <div className="mostfunbg">
             <div className="w-[94%] max-w-[1640px] ml-auto mr-0 mt-0 mb-0 py-[4rem] xs:py-[2rem]" >
            <div className=" 2xl:flex xl:flex lg:flex items-center justify-between block sm:block md:block">
                
                <div className=" 2xl:w-[45%] xl:w-[45%] lg:w-[45%] md:w-[100%] sm:w-[100%] w-[100%] py-[2rem] xs:py-[0rem] xs:text-center sm:text-center space-y-[25px]">
                    <h3 className="text-[40px] xs:text-[24px] font-[700] xs:text-start leading-[130%] text-[#fff]"> {t("MostFunSection.title")} <br /> {t("MostFunSection.title2")}</h3>
                   <ul className="pl-5 space-y-[20px]">
                    <li className="list-disc xs:text-start text-[18px] leading-[28.8px] xs:leading-[125%] xs:text-[18px] text-white">{t("MostFunSection.para1")}</li>
                    <li className="list-disc xs:text-start text-[18px] leading-[28.8px] xs:leading-[125%] xs:text-[18px] text-white">{t("MostFunSection.para2")}</li>
                    <li className="list-disc xs:text-start text-[18px] leading-[28.8px] xs:leading-[125%] xs:text-[18px] text-white">{t("MostFunSection.para3")}</li>
                    <li className="list-disc xs:text-start text-[18px] leading-[28.8px] xs:leading-[125%] xs:text-[18px] text-white">{t("MostFunSection.para4")}</li>
                    <li className="list-disc xs:text-start text-[18px] leading-[28.8px] xs:leading-[125%] xs:text-[18px] text-white">{t("MostFunSection.para5")}</li>
                   </ul>

                    <div className="pt-[25px] flex xs:justify-center xs:py-[25px] xs:pt-[0px]  ">
                        <Button link="#hero" text={`${t('BetaSection.btn')}`} classes="font-[700] bgcolor w-[169px] py-[12px] xs:text-[14px] rounded-[10px]" />
                    </div>
                </div>
                <div className=" flex justify-center items-start  2xl:w-[50%] xl:w-[50%] lg:w-[45%] md:w-[100%] sm:w-[100%] w-[100%] "   >
                    <div className="mostfunbggr w-[100%]">
                    <img className=" w-[100%] py-4" src={mostfun} alt="" />
                    </div>
                </div>
            
            </div>

        </div>
        </div>
    )
}
export default MostFunSection 
