import React from "react";

import RewardSection from "./RewardsSection";
import BenefitSection from "./BenefitsSection";
import BetaSection from "./BetaSection";
import BuySection from "./BuySection";
import FeaturedSection from "./FeaturedSection";
import ProductDetaliSection from "./ProductDetailsSection";
import Roadmap from "./RoadMap";
import TokenDetailsSection from "./TokenDetailsSection";
import TokenomicsSectiion from "./TokenomicsSectiion";
import LicensedCardSection from "./LicensedCardSection";
import DailySection from "./DailySection";
import Media from "./Media";
import PoweredBy from "./PoweredBy";
import FaqList from "./Faq";

import AmbassadorsSection from "./AmbassadorsSection";
import OwnShares from "./OwnShares";
import OWnCarSection from "./OwnCar";
import MostFunSection from "./MostFun";
import FeaturesSection from "./FeaturesSection";
import AboutSection from "./AboutSection";
import MustKnowSection from "./MustKnow";
import BuildSection from "./BuildSection";
import DistributionSection from "./Distribution";
import NftSection from "./NftSection";
import AcquiredSection from "./AcquiredSection";
import OwnersSection from "./OwnersSection";
import { useState } from "react";

const Home = () => {

  const [range, setRange] = useState(1000);
  const [rangeLoad, setRangeLoad] = useState(false);
  const handleRange = (range) => {
    setRange(range)
  }

  const handleRangeLoad = (rangeLoad) => {
    setRangeLoad(rangeLoad)
  }

  return (
    <div className=" bg-[#111010]  overflow-hidden">
      <div id="home" className="">
        <ProductDetaliSection handleRange={handleRange} range={range} handleRangeLoad={handleRangeLoad} rangeLoad={rangeLoad} />
      </div>
      <div>
        <OwnShares />
      </div>
      <div>
        <FeaturedSection />
      </div>
      <div>
        <BetaSection />
      </div>
      {/* <div>
        <LicensedCardSection />
      </div> */}
      <div>
        <OWnCarSection />
      </div>
      <div>
        <BenefitSection />
      </div>
      <div>
        <RewardSection handleRange={handleRange} range={range} handleRangeLoad={handleRangeLoad} rangeLoad={rangeLoad} />
      </div>
      <div>
        <BuySection />
      </div>
      <div>
        <MostFunSection />
      </div>
      <div>
        <FeaturesSection />
      </div>
      <div>
        <AboutSection />
      </div>
      <div>
        <OwnersSection />
      </div>
      <div>
        <DailySection />
      </div>
      <div>
        <TokenomicsSectiion />
      </div>
      <div>
        <MustKnowSection />
      </div>
      <div>
        <BuildSection />
      </div>
      <div>
        <TokenDetailsSection />
      </div>
      <div>
        <DistributionSection />
      </div>
      <div>
        <NftSection />
      </div>
      <div>
        <Roadmap />
      </div>
      <div>
        <AcquiredSection />
      </div>
      {/* <div>
        <AmbassadorsSection />
      </div> */}
      <div>
        <Media />
      </div>
      <div className="partherbg">
        {/* <PoweredBy />  */}
        <FaqList />
      </div>
    </div>
  );
};
export default Home;
